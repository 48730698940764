import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';
export const BENEFITS_PATH = '/benefits/hardware';
// This is not under /benefits due to product requirements
// to not display "benefit" related messaging.
// See https://pelotoncycle.atlassian.net/browse/BIRCH-558
export const PROMOTIONS_DEFAULT_PATH = '/hardware/promotions';
export const PROMOTIONS_PATH = '/hardware/promotions/:promo';

const benefitHardwareRoute = (
  <Route
    exact={true}
    key={BENEFITS_PATH}
    page={true}
    path={BENEFITS_PATH}
    toggleId="oneWellnessHPX"
    component={asyncComponent(() => import('./Deprecated_HardwareBenefitsHandler'))}
  />
);

const hardwarePromotionsRoute = [PROMOTIONS_DEFAULT_PATH, PROMOTIONS_PATH].map(path => (
  <Route
    exact={true}
    key={path}
    page={true}
    path={path}
    component={asyncComponent(() => import('./PromoPage'))}
  />
));

export { benefitHardwareRoute, hardwarePromotionsRoute };
