import { ApolloProvider } from '@apollo/client';
import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import React, { useEffect } from 'react';
import { USE_APOLLO_V3, HIDE_CONSOLE_LOGS } from '@peloton/app-config';
import { useOauth } from '@peloton/auth';
import { getJwtFromCookies } from '@peloton/auth/authToken';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import type { ExtLinkEnv } from '@peloton/external-links';
import { Provider as GraphQLProvider } from '@peloton/graphql';
import { toApolloLinkHeaders } from '@peloton/graphql/toClient';
import type {
  CartQuery,
  CartQueryVariables,
} from '@ecomm/cart-next/graphql/queries/Cart.generated';
import { CartDocument } from '@ecomm/cart-next/graphql/queries/Cart.generated';
import type { Toggles } from '@ecomm/feature-toggle/models/Toggles';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';
import getApolloClientInstance, { getApolloClientInstanceV3 } from './clientInstance';

export const getHeadersWithToken = (headers: object, token?: string | null) => {
  if (!token) {
    return headers;
  }
  const authToken = token.replace(/"/g, '');

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${authToken}`,
    },
  };
};

const Provider: React.FC<
  React.PropsWithChildren<{
    env: ExtLinkEnv;
    toggles: Toggles<string>;
    localeProductStates: NormalizedCacheObject;
    locale?: string;
  }>
> = ({ env, toggles, localeProductStates, locale, ...props }) => {
  const { isAuthenticated, getAccessTokenSilently } = useOauth();
  const [checkoutAccessToken] = useLocalStorage(CHECKOUT_ACCESS_TOKEN_STORAGE_KEY, null);
  let checkoutToken = checkoutAccessToken as string | null;
  if (typeof checkoutToken === 'string') {
    // remove potential double quotes from token being stringified
    checkoutToken = checkoutToken.replace(/"/g, '');
  }
  const isSecretAuthHeaderEnable = toggles['authorizationToken'].active;

  const isOauthCookiesEnabled = toggles['oauth_cookie_enabled'].active;

  const jwt = isOauthCookiesEnabled ? getJwtFromCookies() : checkoutToken;

  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      return {
        ...getHeadersWithToken(headers, token),
        ...toApolloLinkHeaders(isSecretAuthHeaderEnable),
      };
    } else {
      return {
        ...getHeadersWithToken(headers, jwt),
        ...toApolloLinkHeaders(isSecretAuthHeaderEnable),
      };
    }
  }) as ApolloLink;

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        console.log(`GraphQL Error | ${message}`);
      });
    }
  });

  const options = {
    errorLink,
    authLink,
    env,
    initialState: localeProductStates,
  };

  const apolloClient = getApolloClientInstance(options);

  const apolloClientV3 = getApolloClientInstanceV3(locale || 'en-US', options);

  useEffect(() => {
    if (USE_APOLLO_V3 && !HIDE_CONSOLE_LOGS) {
      console.log('Apollo V3 is active for getApolloClientInstanceV3', locale);
      apolloClientV3.query<CartQuery, CartQueryVariables>({
        query: CartDocument,
        variables: { calculateEstimatedShippingPrice: toggles['projectPhoenix'].active },
      });
    } else {
      apolloClient.query<CartQuery, CartQueryVariables>({
        query: CartDocument,
        variables: { calculateEstimatedShippingPrice: toggles['projectPhoenix'].active },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (USE_APOLLO_V3 && !HIDE_CONSOLE_LOGS) {
    console.log('EComm GraphQL Provider V3');
    return <ApolloProvider client={apolloClientV3}>{props.children}</ApolloProvider>;
  }

  return <GraphQLProvider client={apolloClient}>{props.children}</GraphQLProvider>;
};

export default Provider;
