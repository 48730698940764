import type { CtaWithUrlData } from '@ecomm/copy/helpers/types';
import useIsAppNavRedirectExperimentActive from '@ecomm/feature-toggle/AppNavRedirect';

export const useUpdatedSecondaryCta = (
  secondaryCta: CtaWithUrlData | undefined,
  key: string,
) => {
  const isAppNavRedirectExperimentActive = useIsAppNavRedirectExperimentActive();
  const isAppMenu = key === 'nav.productCard.app';

  const updatedSecondaryCta = { ...secondaryCta };

  if (isAppNavRedirectExperimentActive && isAppMenu) {
    updatedSecondaryCta.url = '/app';
  }

  return updatedSecondaryCta;
};
