import React from 'react';
import { toHref } from '@peloton/external-links/models';
import type { PeloLink, ExtLinkEnv } from '@peloton/external-links/models';
import type { Locale } from '@peloton/internationalize';
import { toLocaleFromString, toLocaleHostname } from '@peloton/internationalize';
import { toAvailableLocales } from '@ecomm/internationalize/AvailableLocales';

export type Props = {
  extLinkEnv: ExtLinkEnv;
  canonicalLink: PeloLink;
};

type LinkProps = React.LinkHTMLAttributes<HTMLLinkElement> & { key: string };

const toLinks = (props: Props) =>
  toLinkProps(props).map(({ key, ...linkProps }) => <link key={key} {...linkProps} />);

const toLinkProps = (props: Props): LinkProps[] => [
  // TODO: figure out how to get alternate-language links here, which may help boosting those sites' SEO
  // e.g. <link rel="alternate" href="https://www.onepeloton.co.uk" hreflang="en-GB" />
  // https://support.google.com/webmasters/answer/189077
  toCanonicalLink(props),
  ...toAlternateLinks(props),
];

export const toCanonicalLink = ({ canonicalLink, extLinkEnv }: Props): LinkProps => {
  const hrefUnformatted = toHref(canonicalLink, extLinkEnv);
  const href = hrefUnformatted.toLowerCase();
  return {
    key: `canonical-${href}`,
    rel: 'canonical',
    href,
  };
};

export const toAlternateLinks = ({ canonicalLink, extLinkEnv }: Props): LinkProps[] =>
  toAvailableHrefLangs().map(hrefLang => {
    const href = toHref(canonicalLink, {
      ...extLinkEnv,
      // TODO: This feels hacky.
      //       toHref should have a better way to override the tld.
      hostname: toLocaleHostname(toLocaleFromString(hrefLang)),
    });
    return {
      key: `alternate-${hrefLang}-${href}`,
      rel: 'alternate',
      hrefLang,
      href,
    };
  });

type HrefLang = Locale | 'x-default';

export const toAvailableHrefLangs = (): HrefLang[] => {
  const availableLocales = toAvailableLocales();

  // TODO: Be smarter about which domain is the default
  //       for each language code ('en', 'de', 'fr', etc.)
  //       Currently we rely on undefined behavior by `toHref`
  //       that defaults all unmatched locales to .com.
  //       Maybe make a toLocaleFromHrefLang to use in toAlternateLinks.
  return [...availableLocales, 'x-default'];
};

export default toLinks;
