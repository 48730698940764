import type { ToggleId } from '@ecomm/feature-toggle';
import type { Redirects } from './types';

const wwwRedirectsConfig: Redirects<ToggleId> = [
  { from: '/ChaseSapphire', to: '/chase-sapphire', toggle: 'projectCardinal' },
  { from: '/club', to: '/bike/rentals', toggle: 'bikeRentals' },
  { from: '/new-way-in', to: '/bike/rentals', toggle: 'bikeRentals' },
  { from: '/lg', to: '/digital/partnerships/lg', toggle: 'lgTv' },
  {
    from: '/lg/reactivate',
    to: '/digital/partnerships/lg/reactivate',
    toggle: 'lgTv',
  },
  { from: '/sky', to: '/digital/partnerships/sky', toggle: 'skyTv' },
  {
    from: '/sky/reactivate',
    to: '/digital/partnerships/sky/reactivate',
    toggle: 'skyTv',
  },
  {
    from: '/troy-township',
    to: '/digital/checkout/digital-12m',
    toggle: 'troyTownshipRedirect',
  },
  { from: '/showroom/*', to: '/showrooms' },
  { from: '/spotify', to: '/' },
  { from: '/shop/tread/tread/assembly', to: '/shop/tread/assembly' },
  { from: '/essence', to: '/digital/checkout/digital-60d?utm_source=efoc' },
  { from: '/scf', to: '/' },
  { from: '/try', to: '/home-trial' },
  { from: '/tread-plus/sign-up', to: '/tread-plus' },
  { from: '/offers/bike-rental-student-discount', to: '/shop/opc/bike' },
  { from: '/offers/bike-plus-rental-student-discount', to: '/shop/opc/bike-plus' },
];

export default wwwRedirectsConfig;
