import { InMemoryCache } from 'apollo-cache-inmemory';
import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { ALLOW_GRAPHQL_DEV_TOOLS, HIDE_CONSOLE_LOGS } from '@peloton/app-config';
import type { ExtLinkEnv } from '@peloton/external-links/models';
import { toLink, toUri } from '@peloton/graphql/toClient';
import { toClientNameAndVersion } from '@peloton/graphql/toClientNameAndVersion';
import { toApolloClient } from '@peloton/with-apollo/v3/withApollo';
import fragmentMatcher from './fragmentMatcher';

interface Options {
  errorLink: ApolloLink;
  authLink: ApolloLink;
  env: ExtLinkEnv;
  initialState: NormalizedCacheObject;
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

const getApolloClientInstance = (options?: Options) => {
  if (apolloClient) {
    return apolloClient;
  }

  if (options) {
    const { errorLink, authLink, env, initialState } = options;

    apolloClient = new ApolloClient({
      cache: new InMemoryCache({ fragmentMatcher }).restore(initialState),
      link: ApolloLink.from([errorLink, authLink, toLink(toUri(env))]),
      connectToDevTools: ALLOW_GRAPHQL_DEV_TOOLS,
      ...toClientNameAndVersion('www'),
    });
  }

  return apolloClient;
};

export const getApolloClientInstanceV3 = (locale: string, options: Options) => {
  if (!HIDE_CONSOLE_LOGS) {
    console.log('getApolloClientInstanceV3', locale);
  }
  const client = toApolloClient({ locale, initialState: options.initialState })();
  return client;
};

export default getApolloClientInstance;
