import type { QueryHookResultV2 as QueryHookResult } from '@peloton/graphql/apolloV2';
import type {
  PromoCollectionQuery,
  PromoCollectionQueryVariables,
} from '../queries/contentTypes/allPromos.generated';
import { usePromoCollectionQuery } from '../queries/contentTypes/allPromos.generated';
import type { PromoData } from './usePromoData';
import { transformPromoData } from './usePromoData';

type EnhancedPromoData = PromoData & {
  publishDate: Date;
};

export type AllPromosData = EnhancedPromoData[];
export type Response = QueryHookResult<
  PromoCollectionQuery,
  PromoCollectionQueryVariables
>;

export const transformAllPromosData = (res: Partial<Response>) => {
  const promos = res.data?.allPromos;

  if (!promos || !promos.items) {
    return [];
  }

  return promos.items
    .filter(promo => !!promo)
    .reduce((acc, promo) => {
      return [
        ...acc,
        {
          ...transformPromoData({ data: { promo } }),
          publishDate: promo!.sys.publishedAt, // Promo is guaranteed by the filter
        },
      ];
    }, []);
};

const useAllPromosData = (): AllPromosData => {
  const res = usePromoCollectionQuery({ variables: {} });

  return transformAllPromosData(res);
};

export default useAllPromosData;
