import React from 'react';
import { Helmet } from 'react-helmet';
import type { ExtLinkEnv, PeloLink } from '@peloton/external-links';
import { toHref } from '@peloton/external-links';
import type { Locale } from '@peloton/internationalize';
import { toISOLang } from '@peloton/internationalize';
import { ensureHttpsAddress } from '@page-builder/utils/helpers/regex';
import toLinks from './toLinks';

type OwnProps = {
  canonicalLink: PeloLink;
  title: string;
  description: string;
  keywords: string[];
  ogtype: string;
  imageUrl?: string;
};

type StateProps = {
  extLinkEnv: ExtLinkEnv;
  locale: Locale;
};

type Props = OwnProps & StateProps;

const DEFAULT_IMAGE_URL = window.location.origin + `/images/metadataBike.jpg`;

const MetadataTagsView: React.FC<React.PropsWithChildren<Props>> = ({
  canonicalLink,
  extLinkEnv,
  locale,
  title,
  description,
  keywords,
  ogtype,
  imageUrl,
}) => (
  <Helmet htmlAttributes={{ lang: toISOLang(locale) }}>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords.join(',')} />
    <meta property="og:locale" content={locale} />
    <meta property="og:type" content={ogtype} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={toHref(canonicalLink, extLinkEnv)} />
    <meta
      key="og-image"
      property="og:image"
      content={(imageUrl && ensureHttpsAddress(imageUrl)) ?? DEFAULT_IMAGE_URL}
    />
    {toLinks({ canonicalLink, extLinkEnv })}
  </Helmet>
);

export default MetadataTagsView;
