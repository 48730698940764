export enum Partners {
  Affirm = 'affirm',
  Citizens = 'citizens',
  Klarna = 'klarna',
  Zip = 'zip',
  Synchrony = 'synchrony',
  PayBright = 'paybright',
  KlarnaDirect = 'klarna_direct',
  None = 'none', //No financing option
}

export const PartnerFaqLinksMap = {
  affirm: 'www.affirm.com/faqs',
  affirmForCA: 'www.affirm.ca/help',
  paybright: 'paybright.com/faq',
};

export const PartnerDisplayNameMap = {
  affirm: 'Affirm',
  citizens: 'Citizens',
  zip: 'Zip',
  klarna_direct: 'Klarna',
  klarna: 'Klarna',
  paybright: 'PayBright',
  synchrony: 'Synchrony',
  none: 'None',
};

const PartnerMap = {
  [Partners.Affirm]: Partners.Affirm,
  [Partners.Citizens]: Partners.Citizens,
  [Partners.Klarna]: Partners.Klarna,
  [Partners.Zip]: Partners.Zip,
  [Partners.KlarnaDirect]: Partners.KlarnaDirect,
  [Partners.Synchrony]: Partners.Synchrony,
  [Partners.PayBright]: Partners.PayBright,
  [Partners.None]: Partners.None,
};

export const toPartner = (partner: string) => PartnerMap[partner];

export const getLoanDetailsForPartner = (partner: Partners) => {
  switch (partner) {
    case Partners.Affirm:
      return 'financing.affirm.loanDetails';
    default:
      return undefined;
  }
};
