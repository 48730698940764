import React from 'react';
import { useSelector } from 'react-redux';
import { Markdown } from '@peloton/copy';
import { getRollOverPrice } from '@peloton/subscription-plans';
import { useBikePlusPromo } from '@ecomm/feature-toggle/BikePlusPromoDiscounts';
import useFinancing from '@ecomm/financing/hooks/useFinancing';
import { toDollars, toDollarsCeil } from '@ecomm/models';
import type { ReducerState as PlanState } from '@ecomm/pg-digital-checkout/redux';
import type { BundleType } from '@ecomm/shop/models';
import { useDigitalPrice } from '@ecomm/subscription-plans';

type Props = {
  device: BundleType;
  financingText: string;
};

const FinancingInfoMarkdown: React.FC<React.PropsWithChildren<Props>> = ({
  device,
  financingText,
}) => {
  const { monthly, term, totalBeforeShipping } = useFinancing(device);
  const { isPromo, discount, monthlyPromo } = useBikePlusPromo(
    device,
    'boxingDayBikePlusPromo',
  );

  const price = useDigitalPrice();
  const planRolloverPrice = useSelector((state: PlanState) => getRollOverPrice(state));

  let monthlyPricing = monthly;
  let currentFinancingText = financingText;

  if (isPromo) {
    monthlyPricing = monthlyPromo;
    currentFinancingText = currentFinancingText + '*';
  }

  return (
    <Markdown
      content={currentFinancingText}
      values={{
        term,
        monthlyPayment: toDollarsCeil(monthlyPricing),
        total: toDollarsCeil(totalBeforeShipping - discount),
        price: toDollars(planRolloverPrice || price),
      }}
    />
  );
};

export default FinancingInfoMarkdown;
