import type { CacheResolver } from 'apollo-boost/lib/index';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-boost/lib/index';
// Since this is only a type it should be okay in this isomorphic code
import type NodeFetch from 'node-fetch';
import dataIdFromObject from '@peloton/copy/dataIdFromObject';
import type { Locale } from '@peloton/internationalize';
import type { PublishedCopyBlob } from '@ecomm/copy/helpers';
import { URL, QUERY_TYPENAME_MAP } from './config';

const fetchRetry = require('@vercel/fetch-retry');

const introspectionQueryResultData = require('./fragmentTypes.generated.json');

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const toResolver = (__typename: string): CacheResolver => (_, { id }, { getCacheKey }) =>
  getCacheKey({ __typename, key: id });

const toQuery = (queryMap: Record<string, string>) =>
  Object.keys(queryMap).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: toResolver(queryMap[curr]),
    }),
    {},
  );

type Options = {
  locale: Locale;
  preview: boolean;
  publishedLocaleCopy?: PublishedCopyBlob;
  fetch: typeof fetch | typeof NodeFetch;
  token: string;
  tag: string;
};

const toClient = ({
  locale,
  preview,
  publishedLocaleCopy,
  tag,
  token,
  fetch,
}: Options) => {
  const link = new HttpLink({
    uri: URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    fetch: fetchRetry(fetch),
  });

  const cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject,
    cacheRedirects: {
      Query: toQuery(QUERY_TYPENAME_MAP),
    },
  });

  if (publishedLocaleCopy) {
    cache.restore(publishedLocaleCopy);
  }

  const client = new ApolloClient({
    cache,
    link,
    defaultOptions: {
      query: {
        variables: {
          locale,
          preview,
          tag,
        },
      },
    },
  });

  if (typeof window !== 'undefined') {
    (window as any).__printCopy = () => client.extract();
  }

  return client;
};

export default toClient;
