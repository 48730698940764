import {
  Badge,
  TwoColumnLayout,
  EyebrowHeadlineBody,
  Media,
  grey,
  spacing,
} from '@pelotoncycle/design-system';
import React, { useContext } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useIsTreadPlusLeadGen } from '@ecomm/feature-toggle/useIsTreadPlusLeadGen';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import { Link } from '@ecomm/internationalize-ui';
import { b5 } from '@ecomm/typography';
import { useUpdatedSecondaryCta } from './utils';

type Props = {
  categoryId: string;
  data: Omit<ProductDetailsCardData, 'featureLists'>;
  showCtas: boolean;
  hasSecondaryLinks: boolean;
};

const ProductCard: React.FC<React.PropsWithChildren<Props>> = ({
  categoryId,
  data: {
    key,
    productName,
    description,
    device,
    image: { title, url: imgSrc },
    primaryCta: { text, url, targetBlank },
    secondaryCta,
    ribbonText,
  },
  showCtas,
  hasSecondaryLinks,
}) => {
  const { trackEvent } = useTracking();
  const showCtaWrapper = showCtas && secondaryCta;
  const { hideMenu } = useContext(HeaderNavContext);
  const showRibbon = ribbonText && ribbonText !== 'hide';
  const cachedImage = toCloudinarySrc({ src: imgSrc, width: 120 });
  const isTreadPlusLeadGen = useIsTreadPlusLeadGen(key);

  const updatedSecondaryCta = useUpdatedSecondaryCta(secondaryCta, key);
  const secondaryUrl = updatedSecondaryCta?.url;

  const isGuideRemovedFromMainNav = useIsToggleActive()('removeGuideFromMainNav');
  const shouldShowAccessoriesCtas =
    !showCtas &&
    isGuideRemovedFromMainNav &&
    (key === 'nav.productCard.accessories' ||
      key === 'nav.productCard.GiftCard' ||
      key === 'nav.productCard.Guide');

  return (
    <CardWrapper data-test-id={key} hasSecondaryLinks={hasSecondaryLinks}>
      <LinkWrapper
        to={url}
        href={url}
        target={targetBlank ? '_blank' : '_self'}
        onClick={() => {
          trackEvent({
            properties: {
              category: categoryId,
              parent: key,
              linkTo: url,
              // NOTE: This is currently how linkName is assigned, should we update to a property instead of an empty string?
              linkName: '',
            },
          });
          hideMenu();
        }}
      >
        {showRibbon && (
          <StyledBadge variant="horizontal" theme="accent" position="absolute">
            {ribbonText}
          </StyledBadge>
        )}

        <StyledTwoColumnLayout
          is="div"
          tabIndex={showCtas ? -1 : 0}
          template="seventyThirty"
          reverse
        >
          <ProductInfo showCtaWrapper={Boolean(showCtaWrapper)}>
            <EyebrowHeadlineBody
              eyebrowText={productName}
              bodyText={description}
              size="large"
            />
          </ProductInfo>
          <StyledImage
            media={{
              alt: title,
              desktop: cachedImage,
              tablet: cachedImage,
              mobile: cachedImage,
              type: 'image',
            }}
            objectFit="contain"
            height="auto"
            width="10rem"
          />
        </StyledTwoColumnLayout>
      </LinkWrapper>
      <ProductCtaContainer hasSecondaryLinks={hasSecondaryLinks}>
        {(showCtas || shouldShowAccessoriesCtas) && (
          <CtaWrapper>
            <StyledNavLink
              data-test-id={`${key}.primaryLink`}
              ariaLabel={`${key}.primaryLink`}
              to={url}
              href={url}
              variant="body"
              size="small"
              target={targetBlank ? '_blank' : '_self'}
              onClick={() => {
                trackEvent({
                  properties: {
                    category: categoryId,
                    parent: key,
                    linkTo: url,
                    linkName: text,
                  },
                });
                hideMenu();
              }}
            >
              {text}
            </StyledNavLink>
            {updatedSecondaryCta && !isTreadPlusLeadGen && (
              <StyledNavLink
                data-test-id={`${key}.secondary`}
                ariaLabel={`${key}.secondary`}
                to={secondaryUrl}
                href={secondaryUrl}
                target={updatedSecondaryCta.targetBlank ? '_blank' : '_self'}
                variant="body"
                size="small"
                onClick={() => {
                  trackEvent({
                    properties: {
                      category: categoryId,
                      parent: key,
                      unitName: updatedSecondaryCta.key || '',
                      linkTo: secondaryUrl,
                      linkName: updatedSecondaryCta.text,
                    },
                  });
                  hideMenu();
                }}
              >
                {updatedSecondaryCta.text}
              </StyledNavLink>
            )}
          </CtaWrapper>
        )}
      </ProductCtaContainer>
    </CardWrapper>
  );
};

export default ProductCard;

const CardWrapper = styled.div<{ hasSecondaryLinks: boolean }>`
  position: relative;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${({ hasSecondaryLinks }) =>
      hasSecondaryLinks ? '3.5rem' : '2.5rem'};
  }
`;

const LinkWrapper = styled(Link)`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledTwoColumnLayout = styled(TwoColumnLayout)`
  div {
    justify-content: start;
  }
`;

const StyledImage = styled(Media)`
  min-height: 7rem; /* prevents UI from jumping when images load */
  margin-right: 2.5rem;
  flex-basis: 35% !important;
`;

/*
 1. Optical adjustment to ensure the heading is left-aligned with the text box.
 */
const ProductInfo = styled.div<{ showCtaWrapper: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ showCtaWrapper }) => (showCtaWrapper ? 'start' : 'center')};
  flex-basis: 65% !important;
  [role='heading'] {
    margin-left: -0.05em; /* [1] */
  }
  span {
    font-size: ${spacing[16]};
    margin-bottom: ${spacing[8]};
  }
  p {
    ${b5};
    color: ${grey[70]};
    margin-bottom: 2.8rem;
    max-width: 12.5rem;
    text-transform: none;
  }
`;

/*
 1. Optical adjustment to ensure the CTAs are left aligned with the text box.
 */
const ProductCtaContainer = styled.div<{ hasSecondaryLinks: boolean }>`
  padding-left: 0.2em; /* [1] */
  position: absolute;
  bottom: 0;
  left: ${({ hasSecondaryLinks }) => (hasSecondaryLinks ? '10.5em' : '9.5em')};
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNavLink = styled(Link)`
  padding-top: ${spacing[8]};
  margin-top: -${spacing[8]};

  span {
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
  }
  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover {
    color: ${grey[70]};
  }
`;

const StyledBadge = styled(Badge)`
  line-height: 1.375em !important;
  font-size: 8px !important;
  border-radius: 1px;
  padding: 2px 4px;
`;
