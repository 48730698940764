export interface TeamPageContent {
  disclaimer: string;
  title: string;
  activeChallenges: string;
  otherChallenges: string;
  totalTeammates: string;
  ctaCopyLine1: string;
  ctaCopyLine2: string;
  ctaCopyLine3: string;
  ctaButtonText: string;
}
const mapToView = (content: any): TeamPageContent => {
  if (content['TeamLandingPage'] === undefined) {
    return {} as TeamPageContent;
  }
  return {
    disclaimer:
      content['TeamLandingPage']['checkout.personalized.team.disclaimer']['value'],
    title: content['TeamLandingPage']['checkout.personalized.team.title']['value'],
    activeChallenges:
      content['TeamLandingPage']['checkout.personalized.team.activeChallenges']['value'],
    otherChallenges:
      content['TeamLandingPage']['checkout.personalized.team.otherChallenges']['value'],
    totalTeammates:
      content['TeamLandingPage']['checkout.personalized.team.totalTeammates']['value'],
    ctaCopyLine1:
      content['TeamLandingPage']['checkout.personalized.team.ctaCopyLine1']['value'],
    ctaCopyLine2:
      content['TeamLandingPage']['checkout.personalized.team.ctaCopyLine2']['value'],
    ctaCopyLine3:
      content['TeamLandingPage']['checkout.personalized.team.ctaCopyLine3']['value'],
    ctaButtonText:
      content['TeamLandingPage']['checkout.personalized.team.ctaButtonText']['value'],
  };
};

export const TEAM_PAGE_ENTRY_ID = '6TLvGzXR9f4mUjKPfJlQHJ';

export default {
  entryId: TEAM_PAGE_ENTRY_ID,
  contentType: 'contentAggregate',
  mapToView,
};
