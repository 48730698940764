import { useState, useEffect } from 'react';
import { checkAndRemoveLocalePrefix } from '@peloton/internationalize/models/path';
import { useHasVariation } from '@peloton/split-testing/hooks';

const SIMPLIFIED_NAV_EXPERIMENT = 'Simplified Nav';
const SIMPLIFIED_NAV_VARIATION = 'Variation #1';

export const useIsSimplifiedNavExperimentActive = () => {
  const isSimplifiedNavExperimentActive = useHasVariation(
    SIMPLIFIED_NAV_EXPERIMENT,
    SIMPLIFIED_NAV_VARIATION,
  );
  return isSimplifiedNavExperimentActive;
};

export const setSessionSimplifiedNav = (value: 'true' | 'false') => {
  if (window) {
    window.sessionStorage.setItem('simplifiedNav', value);
  }
};

export const useSimplifiedNav = () => {
  const [shouldDisplaySimplifiedNav, setShouldDisplaySimplifiedNav] = useState(false);
  const inSimplifiedNavVariation = !!useIsSimplifiedNavExperimentActive();

  useEffect(() => {
    const sessionSimplifiedNav = window.sessionStorage.getItem('simplifiedNav');
    // if value has been explicitly set to false, never show simplified nav
    const isSimplifiedNavEnabled =
      sessionSimplifiedNav !== 'false' &&
      (inSimplifiedNavVariation || sessionSimplifiedNav === 'true');
    const isHomePage = checkAndRemoveLocalePrefix(window.location.pathname) === '/';

    if (isHomePage && sessionSimplifiedNav === 'true') {
      setSessionSimplifiedNav('false');
    }

    const shouldDisplay = isSimplifiedNavEnabled && !isHomePage;
    if (shouldDisplay && sessionSimplifiedNav === null) {
      // If it's explicitly been set to false, don't set to true
      setSessionSimplifiedNav('true');
    }

    setShouldDisplaySimplifiedNav(shouldDisplay);
  }, [inSimplifiedNavVariation]);

  return {
    shouldDisplaySimplifiedNav,
  };
};
