import type { NormalizedCacheObject } from '@apollo/client';
import type { ApolloClient } from 'apollo-client';
import { SetGiftInformationOnCartDocument } from '@ecomm/checkout/hooks/SetGiftingInformationOnCart.generated';
import type {
  SetGiftInformationOnCartMutation,
  SetGiftInformationOnCartMutationVariables,
} from '@ecomm/checkout/hooks/SetGiftingInformationOnCart.generated';
import getApolloClientInstance from '@ecomm/graphql/clientInstance';

type giftingInformation = {
  cartId: any;
  recipientName: string;
  gifterName?: string;
  recipientEmail?: string;
  giftMessage?: string;
};

export const _setGiftingInformation = async (giftingInformation: giftingInformation) => {
  const apolloClient: ApolloClient<NormalizedCacheObject> = getApolloClientInstance();

  SetGiftInformationOnCartDocument;
  const response = await apolloClient.mutate<
    SetGiftInformationOnCartMutation,
    SetGiftInformationOnCartMutationVariables
  >({
    mutation: SetGiftInformationOnCartDocument,
    variables: {
      giftingInformation,
    },
  });

  if ((await response)?.data?.setGiftInformationOnCart?.__typename === 'DomainError') {
    throw new Error();
  }
};
