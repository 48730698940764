import { black, white, spacing } from '@pelotoncycle/design-system';
import React, { useContext, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ErrorBoundary, useErrorReporter } from '@peloton/error-reporting';
import { toLocaleWithFallback } from '@peloton/internationalize/models/locale';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { useOnClickOutside } from '@peloton/react/useOnClickOutside';
import { media } from '@peloton/styles';
import { GlobalUiStateContext } from '@acme-ui/global/GlobalUiStateProvider';
import useHeaderReference from '@acme-ui/global/hooks/useHeaderReference';
import useNav from '@content/client/www/nav/useNav';
import {
  HEADER_HEIGHT_DESKTOP_LARGE,
  HEADER_HEIGHT_MOBILE,
  HEADER_HEIGHT_TABLET_XLARGE,
  zIndex,
} from '@ecomm/header/constants';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import { Menu } from '@ecomm/header/models';
import { useProductStates } from '@ecomm/product-states/Context';
import { useGetShowroomStatus } from '@ecomm/showrooms/hooks';
import { useSimplifiedNav } from './hooks/useSimplifiedNav';
import Logo from './Logo/Logo';
import { PanelNav } from './PanelNav';
import { ProductsMenu, StyledMenuBar } from './ProductsMenu';
import UtilitiesMenu, {
  UtilitiesMenuContainer,
  ICON_SIDE_PADDING,
} from './UtilitiesMenu/UtilitiesMenu';

export type Props = {
  className?: string;
  isPanelNavOpen: boolean;
  onHamburgerButtonClick: () => void;
  onCartIconClick: () => void;
  onPanelNavCloseRequest: () => void;
  style?: React.CSSProperties;
  transparent?: boolean;
  sticky?: boolean;
};

const HeaderNavView: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  isPanelNavOpen,
  onHamburgerButtonClick,
  onCartIconClick,
  onPanelNavCloseRequest,
  style = {},
  transparent = false,
  sticky = false,
}) => {
  const locale = useLocale();

  const { currentMenu, setCurrentMenu } = useContext(HeaderNavContext);
  const globalUIContext = useContext(GlobalUiStateContext);
  const headerRef = useHeaderReference();
  // TODO: Remove when nav data is localized to .at in peloContent
  const { content } = useNav(toLocaleWithFallback(locale));
  useOnClickOutside(headerRef, () => setCurrentMenu(Menu.None), false);
  const { shouldDisplaySimplifiedNav } = useSimplifiedNav();

  const showOverlay = useMemo(() => {
    switch (currentMenu) {
      case Menu.GeoPicker:
      case Menu.Account:
      case Menu.None:
        return false;
      default:
        return true;
    }
  }, [currentMenu]);

  useEffect(() => {
    globalUIContext.toggleFreeze(showOverlay);
  }, [globalUIContext, showOverlay]);

  const { isAnyCFUAvailableForPurchase } = useProductStates();
  const shouldRenderCart = isAnyCFUAvailableForPurchase();

  const showrooms = useGetShowroomStatus();
  const isShowroomActive = showrooms !== 'DISABLED';

  return (
    <Container
      aria-label={content?.ariaLabel?.value}
      data-element-id="header"
      data-test-id="globalHeader"
      className={className}
      style={style}
      transparent={transparent}
      sticky={sticky}
      ref={headerRef}
    >
      <Logo />
      {!shouldDisplaySimplifiedNav && (
        <ProductsMenuContainer>
          <ProductsMenu />
        </ProductsMenuContainer>
      )}
      <UtilitiesMenu
        onHamburgerButtonClick={onHamburgerButtonClick}
        onCartIconClick={onCartIconClick}
        shouldRenderCart={shouldRenderCart}
        isShowroomActive={isShowroomActive}
        isPanelNavOpen={isPanelNavOpen}
        shouldDisplaySimplifiedNav={shouldDisplaySimplifiedNav}
      />
      <PanelNav isOpen={isPanelNavOpen} onCloseRequest={onPanelNavCloseRequest} />
      <Overlay
        onClick={() => setCurrentMenu(Menu.None)}
        aria-hidden="true"
        opened={showOverlay}
      />
    </Container>
  );
};

const HeaderNav: React.FC<React.PropsWithChildren<Props>> = props => {
  const { errorReporter } = useErrorReporter();

  return (
    <ErrorBoundary
      renderError={() => <></>}
      reportError={errorReporter.reportError}
      errorContext={{ tags: { component: 'nav' } }}
    >
      <HeaderNavView {...props} />
    </ErrorBoundary>
  );
};

export default HeaderNav;

const transparencyStyles = css`
  + * {
    margin-top: -${spacing[48]};

    ${media.tabletXLarge`
    margin-top: -${spacing[64]};
  `}

    ${media.desktopLarge`
      margin-top: -${spacing[72]};
  `}
  }
`;

const Container = styled.nav<{ transparent: boolean; sticky: boolean }>`
  align-items: center;
  color: ${white};
  display: flex;
  justify-content: space-between;
  height: ${HEADER_HEIGHT_MOBILE}px;
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  padding: 0 ${`calc(1rem - ${ICON_SIDE_PADDING})`} 0 1rem;
  top: 0;
  width: 100%;
  z-index: ${zIndex.HEADER};

  ${media.tabletXLarge`
    height: ${HEADER_HEIGHT_TABLET_XLARGE}px;
    padding: 0 ${`calc(1.5rem - ${ICON_SIDE_PADDING})`} 0 1.5rem;
  `}

  ${media.tabletXLarge`
    height: ${spacing[64]};
    padding: 0 ${`calc(1.5rem - ${spacing[12]})`} 0 1.5rem;
  `}

  ${media.desktopLarge`
    height: ${HEADER_HEIGHT_DESKTOP_LARGE}px;
    padding: 0 ${spacing[32]};
  `}

  div.focus-lock {
    display: flex;
    height: 100%;
    align-items: center;
  }

  ${props => props.transparent && transparencyStyles}

  @media print {
    background: transparent !important;

    svg {
      fill: ${black};
    }

    ${StyledMenuBar},
    ${UtilitiesMenuContainer} {
      display: none;
    }
  }
`;

const ProductsMenuContainer = styled.div`
  display: none;

  ${media.desktopLarge`
      display: flex;
    `}
`;

const Overlay = styled.div<{ opened: boolean }>`
  content: '';
  background: linear-gradient(to bottom, #191c20, rgba(25, 28, 32, 0) 69%);
  position: absolute;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  top: 0;
  left: 0;
  transition: opacity 200ms ease;
  opacity: ${props => (props.opened ? 1 : 0)};
  pointer-events: ${props => (props.opened ? 'auto' : 'none')};
  z-index: -1;
`;
