import 'regenerator-runtime/runtime.js';
import 'slick-carousel/slick/slick.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { reportFocusMethod } from '@peloton/accessibility';
import { identify, load as loadAnalytics, setAnalytics } from '@peloton/analytics';
import { makeAnalytics } from '@peloton/analytics/segment';
import { toClient, CLIENT_CONTEXT as API_CLIENT } from '@peloton/api';
import { ADA_BOT_HANDLE } from '@peloton/app-config';
import { USER_MAPPER_CONTEXT } from '@peloton/auth';
import { isRobot } from '@peloton/browser';
import { addOpenChatCallbackToWindow, injectAdaScript } from '@peloton/chat';
import * as crazyEgg from '@peloton/crazy-egg/CrazyEgg';
import { initChat } from '@peloton/drift';
import {
  toApiEnv,
  environmentFlags,
  toDigitalLinkEnv,
  toLinkEnv,
  toPreorderLinkEnv,
} from '@peloton/env';
import {
  ERROR_REPORTING_CLIENT_CONTEXT,
  configureErrorHandler,
} from '@peloton/error-reporting';
import datadogErrorReporter from '@peloton/error-reporting/datadog/datadogReporter';
import {
  consoleErrorReporter,
  datadogBrowserErrorReporter,
} from '@peloton/error-reporting/reporters';
import {
  EXT_LINK_ENV_CONTEXT,
  toBookingLinkEnv,
  toExtLinkEnv,
} from '@peloton/external-links';
import { toLocaleFromHostname } from '@peloton/internationalize';
import { history, toStore } from '@peloton/redux';
import { loadExperiments, addLifecycleListener } from '@peloton/split-testing';
import { toAccountLinkEnv } from '@account/env';
import { appInit } from '@ecomm/app';
import { mapper as userMapper } from '@ecomm/auth';
import { toStoreReducers, toStoreSagas, features } from '@ecomm/bootstrapping';
import datafiles from '@ecomm/bootstrapping/features/static';
import initData from '@ecomm/bootstrapping/initData';
import { toCommercetoolsClient } from '@ecomm/commercetools/apollo';
import { computeToggles, getQueryToggles } from '@ecomm/feature-toggle';
import { ADA_MIGRATION_FLAG } from '@ecomm/feature-toggle/AdaMigration';
import { OPTIMIZELY_FEATURE_CONTEXT, toFeatures } from '@ecomm/feature-toggle/optimizely';
import { INTL_CONTEXT } from '@ecomm/internationalize';
import { Status } from '@ecomm/models';
import { Vendor, getKeys } from '@ecomm/vendor-keys';
import {
  CLIENT_CONTEXT as ONEWELLNESS_API_CLIENT,
  toOneWellnessClient,
} from '@onewellness/api/client';
import { toLinkEnv as toStudioLinkEnv } from '@studio/env';
import App from './App';

// disable animations in automated tests to avoid flakiness and keep the tests fast
const isCypressTest = !!window.Cypress;

if (isCypressTest) {
  const styleTag = document.createElement('style');
  styleTag.innerHTML = '* { transition: none !important; animation: none !important; }';

  document.head.appendChild(styleTag);
}

const APP = 'www';
const envFlags = environmentFlags(APP)();
const nonLocalAppEnv = { ...envFlags, isLocal: false };

const isDark = !(envFlags.isProd || envFlags.isStaging);
const isLocal = envFlags.isLocal;

// accessibility tool for local development
if (process.env.NODE_ENV === 'development') {
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

const locale = toLocaleFromHostname(window.location.hostname);

const keys = getKeys({ locale, ...envFlags });

const apiEnv = toApiEnv(window.location.hostname, envFlags);

const env = {
  extLink: toExtLinkEnv({
    www: toLinkEnv(envFlags),
    ecomm: toLinkEnv(envFlags),
    digital: toDigitalLinkEnv(envFlags, apiEnv),
    api: apiEnv,
    preorder: toPreorderLinkEnv(envFlags),
    studio: toStudioLinkEnv(envFlags),
    account: toAccountLinkEnv(nonLocalAppEnv),
    booking: toBookingLinkEnv(envFlags),
  }),
  segmentAnalyticsWriteKey: keys.segment,
  optimizelyProjectId: keys.optimizely,
};

const computedToggles = computeToggles(features[locale], isDark, isLocal);

const optimizelyFeatures = toFeatures(
  { locale, isEcommTester: false, email: '' },
  datafiles,
  isLocal ? 'dev' : isDark ? 'uat' : 'production',
);
const queryToggles = getQueryToggles(isDark, isLocal, document.location.search);

const toggles = {
  ...computedToggles,
  ...optimizelyFeatures,
  ...queryToggles,
};

const datadogFeatureFlags = toggles['datadogFeatureFlags']?.active;
const errorHandler = configureErrorHandler([
  consoleErrorReporter,
  ...(!envFlags.isLocal
    ? [datadogFeatureFlags ? datadogErrorReporter : datadogBrowserErrorReporter]
    : []),
]);

const restClient = toClient({
  env: env.extLink,
  headers: {
    'Peloton-Ecomm-Client': 'WEB',
    'Accept-Language': locale,
  },
  authorizationHeaderEnabled: computedToggles['authorizationToken'].active,
});
const oneWellnessClient = toOneWellnessClient(apiEnv, envFlags);
const commercetoolsClient = toCommercetoolsClient('commercetools');

const store = toStore({
  appInit,
  initialState: {
    vendorKeys: {
      [Vendor.Drift]: keys.drift,
      [Vendor.StripeApi]: keys.stripeApi,
      [Vendor.StripeAccount]: keys.stripeAccount,
      [Vendor.Recaptcha]: keys.recaptchaApi,
    },
    extLinkEnv: env.extLink,
    splitTesting: {
      optimizelyProjectId: env.optimizelyProjectId,
      experiments: {},
    },
    toggles: {
      status: Status.Loaded,
      toggles,
    },
    env: {
      flags: envFlags,
      locale,
      appName: APP,
    },
  },

  reducers: toStoreReducers(),

  sagas: toStoreSagas(),

  context: {
    [API_CLIENT]: restClient,

    [ONEWELLNESS_API_CLIENT]: oneWellnessClient,

    [ERROR_REPORTING_CLIENT_CONTEXT]: errorHandler.reportError,

    [EXT_LINK_ENV_CONTEXT]: env.extLink,

    [INTL_CONTEXT]: {
      locale,
    },

    [USER_MAPPER_CONTEXT]: userMapper,

    [OPTIMIZELY_FEATURE_CONTEXT]: datafiles,
  },

  onError: errorHandler.sagaOnError,
});

const load = () => store.dispatch(loadExperiments());

load();
// Reload the experiments anytime optimizely buckets
addLifecycleListener('campaignDecided', load);
setAnalytics(makeAnalytics());
// identify bot traffic
identify({ properties: { isBot: isRobot() } });

const historyWithBaseUrl = history;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

declare global {
  interface Window {
    __promiseHooks: Record<string, Promise<any> | any>;
  }
}

const { copyPromise, productStatePromise } = (window as Window).__promiseHooks;
const promisesForRender = [copyPromise, productStatePromise];

const removeLoadAbandonedTracking = () => {
  // remove load abandonment tracking if it exists for Segment
  if ((window as any).__trackAbandons) {
    document.removeEventListener('visibilitychange', (window as any).__trackAbandons);
  }
  // remove load abandonment tracking if it exists for DataDog
  if ((window as any).__trackDDAbandons) {
    document.removeEventListener('visibilitychange', (window as any).__trackDDAbandons);
  }
};

const postRender = () => {
  setTimeout(() => {
    const isAdaMigrationActive = toggles[ADA_MIGRATION_FLAG].active;
    if (!envFlags.isLocal && !envFlags.isCI && !isAdaMigrationActive) {
      store.dispatch(initChat(keys.drift, locale));
    }

    if (isAdaMigrationActive && ADA_BOT_HANDLE) {
      injectAdaScript(locale, ADA_BOT_HANDLE);
    }

    addOpenChatCallbackToWindow();

    crazyEgg.installPromisified();
    loadAnalytics({ key: keys.segment });
  }, 2000);
};

Promise.all(promisesForRender)
  .then((result: any) => {
    if (process.env.NODE_ENV !== 'test') {
      const [publishedLocaleCopy, localeProductStates] = result;

      root.render(
        <App
          envFlags={envFlags}
          errorHandler={errorHandler}
          oneWellnessClient={oneWellnessClient}
          commercetoolsClient={commercetoolsClient}
          locale={locale}
          publishedLocaleCopy={publishedLocaleCopy}
          localeModalActive
          restClient={restClient}
          store={store}
          toggles={toggles}
          extLinkEnv={env.extLink}
          localeProductStates={localeProductStates}
          history={historyWithBaseUrl}
          postRender={postRender}
        />,
      );
    }

    if (module.hot) {
      module.hot.accept(() => {
        console.error('There was an error in a hot replaced module');
      });
    }

    removeLoadAbandonedTracking();

    reportFocusMethod(document);
  })
  .catch(error => {
    console.error('APPLICATION_LOAD_ERROR', error);
    document.body.innerHTML = '<h1>Hmmm... Something went wrong. Please reload.</h1>';
  });

initData({
  dispatch: store.dispatch,
  location: window.location,
  client: restClient,
}).catch((error: unknown) => {
  console.error('ERROR_LOADING_CART', error);
});
