import { generatePath } from 'react-router';
import { enumFromStringValue } from '@onewellness/utils/helpers';

export enum Audience {
  Corporate = 'corporate',
  DicksSportingGoods = 'dicks-sporting-goods',
  LiverpoolFC = 'liverpoolfc',
  YMCA = 'ymca',
  Student = 'student',
  UHC = 'uhc',
  Lululemon = 'lululemon',
  LululemonHardware = 'lululemon-hardware',
}

export enum Page {
  ApplyBenefit = '/apply-benefit/complete',
  BenefitPricing = '/apply-benefit',
  BenefitCheckout = '/checkout',
  BenefitOptions = '/options',
  CheckEligibility = '/check-eligibility',
  Checkout = '/create-account',
  Download = '/download',
  GetStarted = '/get-started',
  GetSetUp = '/get-set-up',
  MigrationGuide = '/migration-guide',
  LiverpoolFCContinue = '/continue',
  Payment = '/payment',
  SubscriptionError = '/subscription-error',
  VerifyEligibility = '/verify-eligibility',
  Welcome = '/welcome',
  PlanCheckout = '/plan-checkout',
  SwitchPlan = '/switch-plan',
}
export const isRapidPartner = (audience: Audience) =>
  [Audience.DicksSportingGoods, Audience.LiverpoolFC].indexOf(audience) >= 0;

export const toBenefitsPlanCheckoutUrl = (audience: Audience) =>
  toPathWithAudience(Page.PlanCheckout, audience);

export const toBenefitsSwitchPlanUrl = (id: string, audience: Audience) =>
  `${toPathWithAudience(Page.SwitchPlan, audience)}/${id}`;

export const toPathWithAudience = (page: Page, audience: Audience) =>
  generatePath(BASE_PATH + page, { audience });

export const toPageFromPath = (path: string): Page | undefined => {
  const capturingRegex = RegExp(`/benefits/.*(?<page>${Object.values(Page).join('|')})`);
  const match = path.match(capturingRegex);

  const pagePathComponent = match?.groups?.['page'];

  if (!pagePathComponent) return undefined;

  return enumFromStringValue(Page, pagePathComponent);
};

/* Base */
export const BASE_PATH = '/benefits/:audience';
export const LOOKUP_PATH = `${BASE_PATH}${Page.CheckEligibility}`;
export const CHECK_YOUR_INBOX_PATH = `${BASE_PATH}${Page.VerifyEligibility}`;

/* Ecomm */
export const BENEFIT_CHECKOUT_PATH = `${BASE_PATH}${Page.BenefitCheckout}`;
export const BENEFIT_OPTIONS_PATH = `${BASE_PATH}${Page.BenefitOptions}`;
export const SHORT_BENEFITS_OPTION_PATH = '/benefit-center';
export const CHECKOUT_PATH = `${BASE_PATH}${Page.Checkout}`;
export const GET_STARTED_PATH = `${BASE_PATH}${Page.GetStarted}`;

/* Account */
export const APPLY_BENEFIT_PATH = `${BASE_PATH}${Page.ApplyBenefit}`;
export const BACKUP_PAYMENT_PATH = `${BASE_PATH}${Page.Payment}`;
export const BENEFIT_PRICING_PATH = `${BASE_PATH}${Page.BenefitPricing}`;
export const MIGRATION_GUIDE_PATH = `${BASE_PATH}${Page.MigrationGuide}`;
export const LIVERPOOLFC_CONTINUE_PATH = `${BASE_PATH}${Page.LiverpoolFCContinue}`;
export const SUBSCRIPTION_ERROR_PATH = `${BASE_PATH}${Page.SubscriptionError}`;
export const PLAN_CHECKOUT = `${BASE_PATH}${Page.PlanCheckout}`;
export const SWITCH_PLAN = `${BASE_PATH}${Page.SwitchPlan}/:id`;
