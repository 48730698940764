import { grey, white, Icon, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { ProductNavData } from '@ecomm/copy/helpers/types';
import { Link } from '@ecomm/internationalize-ui';

type Props = {
  categoryId: string;
  products: ProductNavData['products'];
  handleClose: () => void;
};

const PrimaryLinks: React.FC<React.PropsWithChildren<Props>> = ({
  products,
  handleClose,
}) => {
  const { trackEvent } = useTracking();

  const productsWithCTA = React.useMemo(
    () => products.filter(({ primaryCta }) => !!primaryCta),
    [products],
  );

  return (
    <PrimaryLinksContainer>
      {productsWithCTA.map(
        ({ key, productName, primaryCta: { key: primaryCtaKey, url } }) => (
          <li key={key}>
            <PrimaryLink
              to={url}
              href={url}
              target="_self"
              children={productName}
              hasUnderline={false}
              onClick={() => {
                trackEvent({
                  properties: {
                    category: key,
                    parent: key,
                    unitName: primaryCtaKey,
                    linkTo: url,
                    linkName: productName,
                  },
                });
                handleClose();
              }}
            />
            <StyledChevron
              name="chevron"
              primaryColor={grey[50]}
              aria-hidden={true}
              rotate={180}
              height={12}
            />
          </li>
        ),
      )}
    </PrimaryLinksContainer>
  );
};

export default PrimaryLinks;

const PrimaryLink = styled(Link)`
  color: ${white};
  margin-right: ${spacing[4]};
  span {
    font-size: 18px;
    line-height: 1.375em;
  }
`;

const PrimaryLinksContainer = styled.ul`
  color: ${white};
  li {
    display: flex;
    align-items: center;
    margin: 1.25rem 0;
  }
`;

const StyledChevron = styled(Icon)`
  margin-left: ${spacing[8]};
`;
