import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import { LinkButton } from '@ecomm/internationalize-ui';
import { useUpdatedSecondaryCta } from './utils';

type Props = {
  categoryId: string;
  products: Omit<ProductDetailsCardData, 'device' | 'featureLists'>[];
};

const SingleProductCtaSection: React.FC<React.PropsWithChildren<Props>> = ({
  categoryId,
  products,
}) => {
  const { trackEvent } = useTracking();
  const [{ key: productCardId, secondaryCta }] = products;

  const updatedSecondaryCta = useUpdatedSecondaryCta(secondaryCta, productCardId);

  if (updatedSecondaryCta) {
    const { key = '', text, url } = updatedSecondaryCta;
    return (
      <Container>
        <Cta
          text={text}
          data-test-id={key}
          href={url}
          color="dark"
          variant="outline"
          size="small"
          width="fixed"
          onClick={() => {
            trackEvent({
              properties: {
                category: categoryId,
                parent: productCardId,
                unitName: key || '',
                linkTo: url,
                linkName: text,
              },
            });
          }}
        />
      </Container>
    );
  }

  return null;
};

export default SingleProductCtaSection;

const Container = styled.div`
  background-color: ${brand.light};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 2.5rem 0;
  display: flex;
  justify-content: center;
`;

const Cta = styled(LinkButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
