import React from 'react';
import { useTracking } from 'react-tracking';
import { toDeviceCategoryFromWindow } from '@peloton/analytics/deviceCategory';
import { trackSegment as track } from '@peloton/analytics/segment';
import { getBrowser } from '@peloton/analytics/utils';
import { useUserInfo } from '@peloton/auth/UserInfoProvider';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';

const TrackingProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { getUser } = useUserInfo();
  const { Track } = useTracking(
    {},
    {
      dispatch: ({ properties, ...data }: any) => {
        // have reference to window here
        const user = getUser();

        const defaultProperties = {
          page: getCurrentRelativePathname(),
          deviceCategory: toDeviceCategoryFromWindow(),
          browser: getBrowser(),
          ...user,
        };
        return track({
          ...data,
          properties: { ...defaultProperties, ...properties },
        });
      },
    },
  );
  return <Track>{children}</Track>;
};
export default TrackingProvider;
