import type { LinkButtonProps, LinkProps } from '@pelotoncycle/design-system';
import React from 'react';
import { useLocale } from '@peloton/internationalize/locales';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/models/path';

type LinkableProps = LinkProps | LinkButtonProps;

export function withI18n(Component: React.ComponentType<any>) {
  const WithI18n = ({ to, href, ...props }: LinkableProps, ref: React.RefObject<any>) => {
    const locale = useLocale();
    const toWithLocale = to && isRelativeUrl(to) ? addCurrentLocaleToUrl(to, locale) : to;
    const hrefWithLocale =
      href && isRelativeUrl(href) ? addCurrentLocaleToUrl(href, locale) : href;
    return <Component to={toWithLocale} href={hrefWithLocale} ref={ref} {...props} />;
  };
  return React.forwardRef(WithI18n);
}

function isRelativeUrl(url: string) {
  return url.startsWith('/') || url.startsWith('#');
}
