import { useHasVariation } from '@peloton/split-testing/hooks';

const APP_NAV_REDIRECT_EXPERIMENT = 'App Nav Re-Direct Test';
const APP_NAV_REDIRECT_VARIATION_1 = 'Variation #1';

const useIsAppNavRedirectExperimentActive = () => {
  return Boolean(
    useHasVariation(APP_NAV_REDIRECT_EXPERIMENT, APP_NAV_REDIRECT_VARIATION_1),
  );
};

export default useIsAppNavRedirectExperimentActive;
