import type {
  ServerRoute,
  ServerRouteGrouping,
} from '@peloton/server-routing/serverRoute';
import { Status } from '@peloton/server-routing/serverRoute';
import {
  NEXT_CHECKOUT_COMPLETE_FINANCING_ROUTE,
  NEXT_CHECKOUT_REVIEW_ROUTE,
  NEXT_CHECKOUT_ROUTE,
} from '@ecomm/pg-checkout-commercetools/routes/checkoutV2Route';

const launchedTLDs = ['.com', '.co.uk', '.ca', '.de', '.com.au'] as const;
// If you need to launch a page in .at, add your route to tldSpecificRewrites
const allTLDs = ['.com', '.co.uk', '.ca', '.de', '.com.au', '.at'] as const;

export const nextjsPages: string[] = [
  'next-authlogin',
  'next-callback',
  'tread',
  'guide',
  'app',
  'app/download',
  'refurbished',
  'bike/rentals',
  'bike/lanebreak',
  'tread/lanebreak',
  'safety',
  'student',
  'delta',
  'protection-plan',
  'angelique-kerber',
  'portfolio',
  'thriveathilton',
  'hiltonhonors',
  'gift-cards',
  'appsFlyerSmartScript.js',
  'row/setup',
  'row/why-row',
  'home-trial',
  'deals',
  'nba',
  'madetomove',
  'lfc',
  'app-membership',
  'hyatt-offers',
  'hyatt-team-offers',
  'wheretotry',
  'partnerlocations',
  'entertainment',
  'sports-gaming',
  '2024unitedairlinesnychalf',
  '2024pelotonberlinhalf',
  'vamos',
  'value',
  'winatread',
  'training',
  'appsummerdeal',
  'appwinterdeal',
  NEXT_CHECKOUT_ROUTE,
  NEXT_CHECKOUT_REVIEW_ROUTE,
  NEXT_CHECKOUT_COMPLETE_FINANCING_ROUTE,
  'umsweeps2024',
  'who-we-are',
  'tradein',
  'welcomebackoffer',
  '2024nycmarathon',
  'app3for1',
  /* ----- Shop ----- */
  'shop/:cfu/complete-cart',
  'shop/:cfu/:buyMethod/complete-cart',
  /* ----- Instructor Match ----- */
  'instructor-match',
  'instructor-match/:quizStep',
  'instructor-match/results/:instructor',
  /* ----- ESG Pages ----- */
  'pledge',
  'sustainability',
  'diversity',
  'community',
  'impact-inclusion',
  /* ----- Company Pages ----- */
  'company',
  'company/team',
  'company/contact',
  /* ----- Legal Pages ----- */
  'terms-of-service',
  'benefits/terms-of-service',
  'corporate-wellness/program-terms',
  'privacy-policy',
  'liverpool-fc-legal-terms',
  'cookies-policy',
  'legal/patents',
  'security',
  'accessibility',
  'rental-terms',
  'us-states-privacy-notice',
  'ip-policy',
  'california-applicant-privacy',
  'community-user-content-moderation-policy',
  'biometric-privacy-policy',
  'membership-terms',
  'homecoming-terms',
  'nyrr-contest-rules',
  'end-of-life-policy',
  'popup',
  'consumer-health-data-policy',
  '2024pelotonberlinhalfterms',
  '2024nycmarathonterms',
  'history-summary-terms',
  /* ----- History Summary Request Form ----- */
  'history-summary',
];

export const tldSpecificRewrites: Record<string, typeof allTLDs[number][]> = {
  'shop/bike/bike-package': ['.com'],
  'shop/bike/bike-starter-package-us': ['.com'],
  'shop/bike/bike-ultimate-package-us': ['.com'],
  'shop/bike-plus/bike-plus-basics-package-us': ['.com'],
  'shop/bike-plus/bike-plus-starter-package-us': ['.com'],
  'shop/bike-plus/bike-plus-ultimate-package-us': ['.com'],
  'shop/tread/tread-basics-package-us': ['.com'],
  'shop/tread/tread-starter-package-us': ['.com'],
  'shop/tread/tread-ultimate-package-us': ['.com'],
  'shop/tread-plus/tread-plus-basics-package-us': ['.com'],
  'shop/tread-plus/tread-plus-starter-package-us': ['.com'],
  'shop/tread-plus/tread-plus-ultimate-package-us': ['.com'],
  'shop/guide/guide-us': ['.com'],
  'shop/guide/guide-starter-package-us': ['.com'],
  'shop/guide/guide-ultimate-package-us': ['.com'],
  'shop/row/row-basics-package-us': ['.com'],
  'shop/row/row-starter-package-us': ['.com'],
  'shop/row/row-ultimate-package-us': ['.com'],

  'shop/opc/bike': ['.com', '.ca', '.de'],
  'shop/opc/bike/rent': ['.com'],
  'shop/refurbished/bike': ['.com', '.ca', '.de', '.co.uk'],
  'shop/opc/bike-plus': ['.com', '.ca', '.de'],
  'shop/opc/bike-plus/rent': ['.com'],
  'shop/refurbished/bike-plus': ['.com', '.ca', '.co.uk', '.de'],
  'shop/refurbished/bike/buy': ['.com', '.ca'],
  'shop/refurbished/bike-plus/buy': ['.com', '.ca'],

  'shop/bike/bike-basics-package-ca': ['.ca'],
  'shop/bike/bike-starter-package-ca': ['.ca'],
  'shop/bike/bike-ultimate-package-ca': ['.ca'],
  'shop/bike-plus/bike-plus-basics-package-ca': ['.ca'],
  'shop/bike-plus/bike-plus-ultimate-package-ca': ['.ca'],
  'shop/bike-plus/bike-plus-starter-package-ca': ['.ca'],
  'shop/tread/tread-basics-package-ca': ['.ca'],
  'shop/tread/tread-starter-package-ca': ['.ca'],
  'shop/tread/tread-ultimate-package-ca': ['.ca'],
  'shop/guide/guide-ca': ['.ca'],
  'shop/guide/guide-starter-package-ca': ['.ca'],
  'shop/guide/guide-ultimate-package-ca': ['.ca'],
  'shop/row/row-basics-package-ca': ['.ca'],
  'shop/row/row-starter-package-ca': ['.ca'],
  'shop/row/row-ultimate-package-ca': ['.ca'],

  'shop/bike/the-intl-bike-package': ['.co.uk'],
  'shop/bike/bike-starter-package-uk': ['.co.uk'],
  'shop/bike/bike-ultimate-package-uk': ['.co.uk'],
  'shop/bike-plus/bike-plus-basics-package-uk': ['.co.uk'],
  'shop/bike-plus/bike-plus-starter-package-uk': ['.co.uk'],
  'shop/bike-plus/bike-plus-ultimate-package-uk': ['.co.uk'],
  'shop/tread/tread-basics-package-uk': ['.co.uk'],
  'shop/tread/tread-starter-package-uk': ['.co.uk'],
  'shop/tread/tread-ultimate-package-uk': ['.co.uk'],
  'shop/guide/guide-uk': ['.co.uk'],
  'shop/guide/guide-starter-package-uk': ['.co.uk'],
  'shop/guide/guide-ultimate-package-uk': ['.co.uk'],

  'shop/bike/bike-basics-package-au': ['.com.au'],
  'shop/bike/bike-starter-package-au': ['.com.au'],
  'shop/bike/bike-ultimate-package-au': ['.com.au'],
  'shop/bike-plus/bike-plus-basics-package-au': ['.com.au'],
  'shop/bike-plus/bike-plus-starter-package-au': ['.com.au'],
  'shop/bike-plus/bike-plus-ultimate-package-au': ['.com.au'],
  'shop/tread/tread-basics-package-au': ['.com.au'],
  'shop/tread/tread-starter-package-au': ['.com.au'],
  'shop/tread/tread-ultimate-package-au': ['.com.au'],
  'shop/guide/guide-au': ['.com.au'],
  'shop/guide/guide-starter-package-au': ['.com.au'],
  'shop/guide/guide-ultimate-package-au': ['.com.au'],

  'shop/bike/the-bike-package-de': ['.de'],
  'shop/bike/bike-starter-package-de': ['.de'],
  'shop/bike/bike-ultimate-package-de': ['.de'],
  'shop/bike-plus/bike-plus-basics-package-de': ['.de'],
  'shop/bike-plus/bike-plus-starter-package-de': ['.de'],
  'shop/bike-plus/bike-plus-ultimate-package-de': ['.de'],
  'shop/tread/tread-basics-package-de': ['.de'],
  'shop/tread/tread-starter-package-de': ['.de'],
  'shop/tread/tread-ultimate-package-de': ['.de'],

  /* ----- Umich Rental Pages ----- */
  'offers/bike-rental-student-discount': ['.com'],
  'offers/bike-plus-rental-student-discount': ['.com'],

  /* ----- Used Bike Page ----- */
  'used-bikes': ['.com', '.ca', '.co.uk'],

  'tread-plus': ['.com'],
  row: ['.com', '.ca'],

  performance: ['.com'],

  /* ----- Bike / Bike+ PLPs ----- */
  /* US is handled by an experiment controlled in toggled redirects  */
  bike: ['.ca', '.de', '.co.uk', '.com.au', '.at'],
  'bike-plus': ['.ca', '.de', '.co.uk', '.com.au', '.at'],

  /* ----- .at Pages ----- */
  tread: ['.at'],
  app: ['.at'],
  about: ['.at'],
  impressum: ['.at'],
  'privacy-policy': ['.at'],
  'membership-terms': ['.at'],
  'terms-of-service': ['.at'],
  accessibility: ['.at'],
  mymembership: ['.at'],
  madetomove: ['.de', '.at'],
  '2024nycmarathon': ['.at'],
  '2024nycmarathonterms': ['.at'],
  pledge: ['.at'],
  sustainability: ['.at'],
  diversity: ['.at'],
  community: ['.at'],
  'impact-inclusion': ['.at'],
  appsummerdeal: ['.at'],
};

const toBaseRewrite = (tld: string, pageRoute: string): ServerRoute => ({
  source: `https://www.onepeloton${tld}/${pageRoute}`,
  destination: `/next-www/onepeloton${tld}/${pageRoute}`,
  dontForce: true,
  status: Status.Rewrite,
});

const toHomepageRewrites = (): ServerRoute[] => {
  return allTLDs.map(tld => ({
    source: `https://www.onepeloton${tld}/`,
    destination: `/next-www/onepeloton${tld}/`,
    dontForce: false,
    status: Status.Rewrite,
  }));
};

const toSandboxRewrites = (): ServerRoute[] => {
  return allTLDs.map(tld => {
    return {
      source: `/next-www/onepeloton${tld}/sandbox/*`,
      destination: `/next-www/onepeloton${tld}/sandbox`,
      dontForce: true,
      status: Status.Rewrite,
    };
  });
};

const toApparelPDPRewrites = (): ServerRoute[] => {
  return launchedTLDs.map(tld => {
    return {
      source: `/next-www/onepeloton${tld}/shop/apparel/product/*`,
      destination: `/next-www/onepeloton${tld}/shop/apparel/product/splat.html`,
      dontForce: true,
      status: Status.Rewrite,
    };
  });
};

const toApparelProductGridRewrites = (): ServerRoute[] => {
  return launchedTLDs.map(tld => {
    return {
      source: `/next-www/onepeloton${tld}/shop/apparel/*`,
      destination: `/next-www/onepeloton${tld}/shop/apparel/splat.html`,
      dontForce: true,
      status: Status.Rewrite,
    };
  });
};

const toNextDataRewrites = (): ServerRoute[] => {
  return allTLDs.map(tld => {
    return {
      source: `https://www.onepeloton${tld}/_next/data/*`,
      destination: `/next-www/onepeloton${tld}/_next/data/:splat`,
      dontForce: true,
      status: Status.Rewrite,
    };
  });
};

const toTLDSpecificRewrites = (): ServerRoute[] => {
  return Object.entries(tldSpecificRewrites).flatMap(([pageRoute, tlds]) => {
    const dedupedTlds = [...new Set(tlds)];
    return dedupedTlds.map(tld => toBaseRewrite(tld, pageRoute));
  });
};

const toNotFound = (): ServerRoute => ({
  source: '/next-www/*',
  destination: '/index.html',
  dontForce: true,
  status: Status.Not_Found,
});

const nextjsRedirects: ServerRouteGrouping = {
  description: 'nextjs routes',
  routes: [
    ...toSandboxRewrites(),
    ...toApparelPDPRewrites(),
    ...toApparelProductGridRewrites(),
    // Need to "force" so the CRA homepage doesn't get render first
    ...toHomepageRewrites(),
    ...toNextDataRewrites(),
    ...nextjsPages.flatMap(pageRoute =>
      launchedTLDs.map(tld => toBaseRewrite(tld, pageRoute)),
    ),
    ...toTLDSpecificRewrites(),
    toNotFound(),
  ],
};

export default nextjsRedirects;
