import { useEffect } from 'react';
import { withRouter } from 'react-router';
import type { RouteComponentProps } from 'react-router';
import { RELOAD_NEXT_ROUTES } from '@peloton/app-config';
import {
  nextjsPages,
  tldSpecificRewrites,
} from '@ecomm/server-routing/config/nextjsRedirects';

const tldSpecificPageSlugs = Object.keys(tldSpecificRewrites);

const NextRouteProtection: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({
  history,
}) => {
  useEffect(() => {
    const unblock = history.block(location => {
      const nextRoutes = [...nextjsPages, ...tldSpecificPageSlugs, '/'];
      const slug =
        location.pathname === '/' ? location.pathname : location.pathname.substring(1);

      if (RELOAD_NEXT_ROUTES && nextRoutes.includes(slug)) {
        window.location.assign(location.pathname);
        return false;
      }

      return unblock();
    });

    return () => {
      unblock();
    };
  });

  return null;
};

export default withRouter(NextRouteProtection);
