import { Container } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const AnimatedSparkleIcon = () => (
  <Container
    position="relative"
    width="20px"
    height="20px"
    data-test-id="animated-sparkle-icon"
  >
    <LargeSparkleStar />
    <SmallSparkleStar />
  </Container>
);

export default AnimatedSparkleIcon;

const SparkleStar: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  className,
}) => {
  return (
    <svg
      viewBox="0 0 31.712498 31.712429"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m 15.856301,-9.9639893e-8 c 0,0 0.8676,8.171129999639893 4.2763,11.579930099639891 3.4088,3.4087 11.5799,4.2763 11.5799,4.2763 0,0 -8.1711,0.8676 -11.5799,4.2764 -3.4087,3.4087 -4.2763,11.5798 -4.2763,11.5798 0,0 -0.8676,-8.1711 -4.2764,-11.5798 C 8.1711014,16.72383 1.385498e-6,15.85623 1.385498e-6,15.85623 c 0,0 8.483800014502,-1.1802 11.579899614502,-4.2763 C 14.676001,8.4837299 15.856301,-9.9639889e-8 15.856301,-9.9639893e-8 Z" />
    </svg>
  );
};

/*
Assuming 2000ms total animation:
0ms -> 500ms: animate to visible
500ms -> 1000ms: animate to invisible
1000ms -> 2000ms: invisible (delay)
 */
const shrinkGrow = keyframes`
  0% {
    transform: scale(0);
  }
  
  25% {
    transform: scale(1);
  }
  
  50%, 100% {
    transform: scale(0);
  }
`;

/*
Assuming 2000ms total animation:
0ms -> 400ms: invisible (delay)
400ms -> 900ms: animate to visible
900ms -> 1400ms: animate to invisible
1400ms -> 2000ms: invisible (delay)
 */
const shrinkGrowDelayed = keyframes`
  0%, 20% {
    transform: scale(0);
  }
  
  45% {
    transform: scale(1);
  }
  
  70%, 100% {
    transform: scale(0);
  }
`;

const sharedSparkleStyles = css`
  position: absolute;
  fill: currentColor;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

const LargeSparkleStar = styled(SparkleStar)`
  height: 15px;
  width: 15px;
  left: 0;
  bottom: 0;
  animation-name: ${shrinkGrow};
  ${sharedSparkleStyles};
`;

const SmallSparkleStar = styled(SparkleStar)`
  height: 7px;
  width: 7px;
  right: 2px;
  top: 2px;
  animation-name: ${shrinkGrowDelayed};
  ${sharedSparkleStyles};
`;
