import { decamelizeKeys } from 'humps';
import DomainError from '@peloton/domain-error/DomainError';
import type { Currency } from '@peloton/internationalize/models/currency';
import { toDollars } from '@ecomm/models/Money';

interface AffirmWindow extends Window {
  affirm: AffirmApi;
}

type AffirmApi = {
  checkout: AffirmCheckout;
  ui: AffirmUI;
  events: AffirmEvents;
};

type AffirmEvents = {
  on: (eventName: string, callback: AffirmPrequalCallbackHandler) => void;
  off: (eventName: string, callback: AffirmPrequalCallbackHandler) => void;
};

type AffirmCheckout = {
  (info: AffirmDecamelizedCheckoutInfo): void;
  open(): void;
};

export type AffirmErrorHandler = (e: any) => void;

type AffirmRawPrequalData = {
  status: 'approved' | 'not_approved' | 'unknown';
  terms?: AffirmPrequalTerm[];
};

type AffirmPrequalTerm = {
  apr: number;
  created: string;
  installment_amount: number;
  installment_count: number;
  interest_amount: number;
  plan_ari: string;
  prequal_amount: number;
};

type AffirmPrequalData = {
  status: 'approved' | 'not_approved' | 'unknown';
  installment_amount?: number;
  installment_count?: number;
  interest_amount?: number;
  prequal_amount?: number;
};

export type AffirmPrequalCallbackHandler = (data: AffirmPrequalData) => void;

type AffirmError = {
  on(event: string, handler: AffirmErrorHandler): void;
};

type AffirmUI = {
  error: AffirmError;
  ready(handler: () => void): boolean;
  refresh(): void;
};

// Using any since decamelize process is dynamic.
// The checkout fn below enforces we get the right type as input.
type AffirmDecamelizedCheckoutInfo = any;

export type AffirmCheckoutInfo = AffirmCheckoutCartInfo &
  AffirmMetaInfo & {
    billing: AffirmContact;
    shipping: AffirmContact;
  };

export type AffirmCheckoutCartInfo = {
  items: AffirmCheckoutItem[];
  taxAmount: number;
  shippingAmount: number;
  total: number;
};

export type AffirmMetaInfo = {
  currency?: Currency;
  financingProgram?: string;
  merchant: {
    name: string;
    userConfirmationUrl: string;
    userCancelUrl: string;
  };
};

export type AffirmCheckoutItem = {
  displayName: string;
  sku: string;
  unitPrice: number;
  qty: number;
  itemImageUrl: string;
  itemUrl: string;
};

export type AffirmContact = {
  name: {
    first: string;
    last: string;
  };
  address: AffirmAddress;
  email: string;
};

export type AffirmAddress = {
  street1: string;
  street2?: string;
  city: string;
  region1_code: string;
  postal_code: string;
  country: string;
};

export const getAffirm = (): AffirmApi => ((window as any) as AffirmWindow).affirm;

export const prequalCompleteCallback = (
  completionCallback: AffirmPrequalCallbackHandler,
) => {
  const onPrequalComplete = (data: AffirmRawPrequalData) => {
    const transformedData: AffirmPrequalData = {
      status: data.status,
    };

    if (data.terms) {
      const [longestTerm] = data.terms.sort(
        (a, b) => b.installment_count - a.installment_count,
      );

      const {
        installment_amount,
        installment_count,
        interest_amount,
        prequal_amount,
      } = longestTerm;

      transformedData.installment_amount = toDollars(installment_amount);
      transformedData.installment_count = installment_count;
      transformedData.interest_amount = toDollars(interest_amount);
      transformedData.prequal_amount = toDollars(prequal_amount);
    }

    completionCallback(transformedData);
  };

  const PREQUAL_COMPLETE = 'prequal:complete';
  const affirm = getAffirm();
  affirm.ui.ready(() => {
    affirm.events.on(PREQUAL_COMPLETE, onPrequalComplete);
  });

  return () => {
    affirm.ui.ready(() => {
      affirm.events.off(PREQUAL_COMPLETE, onPrequalComplete);
    });
  };
};

export const checkout = (info: AffirmCheckoutInfo, onError: AffirmErrorHandler) => {
  try {
    const affirm = getAffirm();
    affirm.ui.ready(() => {
      try {
        affirm.ui.error.on('close', onError);
        affirm.checkout(decamelizeKeys(info));
        affirm.checkout.open();
      } catch (e) {
        const error = new DomainError(
          `Error in affirm checkout callback: ${e}`,
          Error(e),
        );
        onError(error);
      }
    });
  } catch (e) {
    const error = new DomainError('Error in affirm checkout', e);
    onError(error);
  }
};

export const refreshAffirmUI = () => {
  const affirm = getAffirm();
  affirm.ui.ready(() => {
    affirm.ui.refresh();
  });
};

export default getAffirm;
