import { useEffect } from 'react';
import type { ReportError } from '@peloton/error-reporting';
import type {
  MutationHookOptionsV2 as BaseMutationHookOptions,
  QueryHookOptionsV2 as BaseQueryHookOptions,
  QueryHookResultV2 as QueryHookResult,
  MutationFnV2 as MutationFn,
  MutationResultV2 as TMutationResult,
  OperationVariablesV2 as OperationVariables,
  DocumentNodeV2 as DocumentNode,
} from '@peloton/graphql/apolloV2';
import {
  useMutationV2 as useMutation,
  useQueryV2 as baseUseQuery,
} from '@peloton/graphql/apolloV2';

export type QueryResult<TData, TVariables> = QueryHookResult<TData, TVariables>;
export type MutationFunction<TData, TVariables> = MutationFn<TData, TVariables>;
export type BaseMutationOptions<TData, TVariables> = BaseMutationHookOptions<
  TData,
  TVariables
>;
export type MutationResult<TData> = TMutationResult<TData>;

export type QueryHookOptions<TData, TVariables, TCache = object> = BaseQueryHookOptions<
  TVariables,
  TCache
> & {
  reportSwallowedError?: ReportError;
  throwError?: boolean;
  useApq?: boolean;
};

export type MutationHookOptions<
  TData,
  TVariables,
  TCache = object
> = BaseMutationHookOptions<TData, TVariables, TCache>;

export const useQuery = <TData = any, TVariables = OperationVariables, TCache = object>(
  query: DocumentNode,
  {
    reportSwallowedError,
    throwError = true,
    useApq,
    ...options
  }: QueryHookOptions<TData, TVariables, TCache> = {},
) => {
  const response = baseUseQuery<TData, TVariables, TCache>(query, {
    suspend: true,
    context: { useApq, ...options.context },
    ...options,
  });
  // Prevent throwing and logging error if it was the most recently thrown error
  // Reliable, because response object is cached in Apollo
  useEffect(() => {
    if (response.error) {
      if (throwError) {
        throw response.error;
      }

      if (reportSwallowedError) {
        const errorProps = {
          tags: {
            lib: 'apollo',
          },
        };
        reportSwallowedError(response.error, errorProps);
      }
    }
  }, [response.error]);

  return response;
};

export { useMutation };

// These hooks are available in @apollo/react-hooks (and the upcoming @apollo/client 3.0)

export const useLazyQuery = <TData, TVariables>(_: DocumentNode, __?: any) => {
  console.error(
    '@ecomm/copy needs to upgrade to @apollo/react-hooks in order to use this feature',
  );
};

export type LazyQueryHookOptions<
  TData,
  TVariables,
  TCache = object
> = BaseQueryHookOptions<TVariables, TCache> & { throwError?: boolean };
