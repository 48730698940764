import { Label, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { a11yOutline } from '@ecomm/buttons/buttons';
import type { Theme } from './themes';
import { THEMES } from './themes';

type EcommBannerProps = {
  theme: string | undefined;
  clickable?: boolean;
  children: React.ReactNode;
};

// Setting a default here because `theme` is briefly `undefined` when the banner is unpublished in Contentful
const EcommBanner = React.forwardRef<HTMLDivElement, EcommBannerProps>(
  ({ theme, clickable = true, children }, ref) => (
    <ThemedBanner
      data-element-id="banner"
      ref={ref}
      theme={theme ?? 'defaultTheme'}
      clickable={clickable}
    >
      {children}
    </ThemedBanner>
  ),
);

EcommBanner.displayName = 'EcommBanner';

export default EcommBanner;

const BannerCta = css`
  position: relative;
  margin-left: 0px;
  white-space: nowrap;
  font-weight: 500;

  &::after {
    border-bottom: 1px solid ${({ theme }): Theme => THEMES[theme].color};
    content: '';
    left: 0;
    position: absolute;
    top: calc(50% + 9px);
    width: 100%;
  }
`;

const ctaTextStyles = css`
  position: relative;
  margin-left: ${spacing[8]};
  white-space: nowrap;
  font-weight: 500;

  &::after {
    border-bottom: 1px solid ${({ theme }): Theme => THEMES[theme].color};
    content: '';
    left: 0;
    position: absolute;
    top: calc(50% + 5px);
    margin-top: 2px;
    width: 100%;
  }

  ${media.tabletLarge`
    margin-left: 0px;
  `}
`;

export const themedBannerLayoutStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px 30px;
  text-align: center;
  transition: all 0.15s ease-in-out;
  width: 100%;

  ${media.tablet`
    padding: 20px 40px;
  `}

  ${media.desktop`
    min-height: 66px;
  `}
`;

const ThemedBanner = styled.div`
  background: ${({ theme }): Theme => THEMES[theme].backgroundColor};
  color: ${({ theme }): Theme => THEMES[theme].color};
  ${themedBannerLayoutStyles}

  @media print {
    display: none;
  }

  em {
    color: ${({ theme }): Theme => THEMES[theme].emphasisColor};
    font-style: normal;
  }

  sup {
    display: inline-block;
    font-size: 50%;
    margin-top: -3px;
    vertical-align: top;
  }

  a {
    ${BannerCta}
  }

  ${({ clickable }: Pick<EcommBannerProps, 'clickable'>) =>
    clickable &&
    css`
      &:hover {
        background: ${({ theme }): Theme => THEMES[theme].hoverColor}
        transition: background 250ms ease;
      }
    `};
`;

export const CtaText = styled(Label)`
  ${ctaTextStyles}
  ${a11yOutline}
`;
