import type { CheckoutContentType } from '@content/client/fixtures/checkoutAggregateFixture';
const mapToView = (content: CheckoutContentType) => {
  return {
    title: content['checkout']['checkout.form.legends.email'],
    emailAddress: content['checkout']['checkout.email.emailAddress'],
    helptext: content['checkout']['checkout.email.helptext'],
    emailAddressRequired: content['checkout']['checkout.email.emailAddressRequired'],
    cta: content['checkout']['checkout.user.signOut.cta'],
    information: content['checkout']['checkout.general.information'],
    subscribeEmail: content['checkout']['checkout.general.subscribeEmail'],
    reviewOrder: content['checkout']['checkout.general.reviewOrder'],
    requiredFields: content['checkout']['checkout.errors.incomplete'],
    validPhoneNumber: content['checkout']['checkout.errors.shipping.invalidPhone'],
    shipping: content['checkout']['checkout.form.legends.shipping'],
    verifyStudentStatus: content['checkout']['checkout.form.legends.verifyStudentStatus'],
    firstName: content['checkout']['checkout.form.labels.shipping.name.first'],
    firstNameRequired: content['checkout']['checkout.address.firstNameRequired'],
    lastName: content['checkout']['checkout.form.labels.shipping.name.last'],
    lastNameRequired: content['checkout']['checkout.address.lastNameRequired'],
    phoneNumber: content['checkout']['checkout.address.phoneNumber'],
    phoneNumberRequired: content['checkout']['checkout.address.phoneNumberRequired'],
    address: content['checkout']['checkout.form.labels.shipping.address.line1'],
    optionalAddressLabel:
      content['checkout']['checkout.form.labels.shipping.address.line1.optional'],
    addressRequired: content['checkout']['checkout.address.addressRequired'],
    aptSuite: content['checkout']['checkout.address.aptSuit'],
    aptSuite2: content['checkout']['checkout.address.aptSuite'],
    city: content['checkout']['checkout.form.labels.billing.address.city'],
    cityRequired: content['checkout']['checkout.address.cityRequired'],
    state: content['checkout']['checkout.form.labels.shipping.address.state'],
    states: content['checkout']['checkout.form.states'],
    zipCode:
      content['checkout']['updatePayment.pgCreditCard.form.label.billingPostalCode'],
    zipCodeRequired: content['checkout']['checkout.address.zipCodeRequired'],
    zipCodeInvalid: content['checkout']['checkout.address.zipCodeInvalid'],
    phoneNumberInvalid: content['checkout']['checkout.address.phoneNumberInvalid'],
    stateRequired: content['checkout']['checkout.address.stateRequired'],
    country: content['checkout']['pgCheckout.billingInfo.country'],
    verifyAddress: content['checkout']['addressValidation.error.title'],
    accurateAddress: content['checkout']['addressValidation.error.text'],
    addressEntered: content['checkout']['addressValidation.error.originalLabel'],
    confirmAddress: content['checkout']['addressValidation.buttonLabel'],
    editAddress: content['checkout']['addressValidation.error.editText'],
    billingAddressCheck: content['checkout']['checkout.form.billingSameAsShipping'],
    billing: content['checkout']['checkout.address.billing'],
    estDelivery: content['checkout']['checkout.otd.estDelivery'],
    estDeliveryMsg: content['checkout']['checkout.otd.estDeliveryMsg'],
    titlePayment: content['checkout']['checkout.review.paymentMethod'],
    card: content['checkout']['checkout.form.paymentOptions.card'],
    cardMessage: content['checkout']['checkout.form.securemessage'],
    cardInformation: content['checkout']['checkout.payment.cardInformation'],
    cardHolderName: content['checkout']['checkout.form.labels.creditCardName'],
    cardHolderNameRequired:
      content['checkout']['checkout.payment.cardHolderNameRequired'],
    cardNumber: content['checkout']['checkout.form.labels.creditCardNumber'],
    cardNumberRequired: content['checkout']['pgCheckout.form.missing.creditCardNumber'],
    cardNumberIncomplete: content['checkout']['checkout.payment.cardNumberIncomplete'],
    cardNumberInvalid: content['checkout']['checkout.payment.cardNumberInvalid'],
    cardExpDate: content['checkout']['checkout.form.labels.creditCardExpiry'],
    cardExpDateRequired: content['checkout']['pgCheckout.form.missing.creditCardExpiry'],
    cardExpDateIncomplete: content['checkout']['checkout.payment.cardExpDateIncomplete'],
    cardExpDateInPast: content['checkout']['checkout.payment.cardExpDateInPast'],
    cardExpYearInvalid: content['checkout']['checkout.payment.cardExpYearInvalid'],
    cardCvc: content['checkout']['checkout.form.labels.creditCardCVC'],
    cardCvcRequired: content['checkout']['pgCheckout.form.missing.creditCardCVC'],
    panelTitle: content['checkout']['checkout.review.title'],
    shippingTo: content['checkout']['checkout.review.shippingTo'],
    shippingMethodTitle: content['checkout']['checkout.review.shippingMethod'],
    billingAddress: content['checkout']['checkout.review.billing'],
    membershipConfirmation: content['checkout']['checkout.review.membershipConfirmation'],
    paymentChargingConfirmation:
      content['checkout']['checkout.review.paymentChargingConfirmation'],
    placeOrder: content['checkout']['checkout.review.submit'],
    siteProtectedMsg: content['checkout']['recaptcha.legalText'],
    gifterName: content['checkout']['checkout.gifter.name'],
    confirmAddressTitle: content['checkout']['addressValidation.title'],
    confirmAddressText: content['checkout']['addressValidation.text'],
    suggestedAddressLabel: content['checkout']['addressValidation.suggestedLabel'],
    originalAddressLabel: content['checkout']['addressValidation.originalLabel'],
    memberConfirmationAcknowledge:
      content['checkout']['checkout.review.memberConfirmationAck'],
    shippingMethodDetails: content['checkout']['checkout.review.shippingMethodDetails'],
    shippingMethodDetailedInfo:
      content['checkout']['checkout.review.shippingMethodDetailedInfo'],
    shippingMethodDetailsBike: content['checkout']['shipping.methodDetails.actual.bike'],
    shippingMethodDetailsBikeAndTreadAuthRemoved:
      content['checkout']['shipping.methodDetails.actual.bikeAndTreadAuthRemoved'],
    shippingMethodDetailsTreadAuthRemoved:
      content['checkout']['shipping.methodDetails.actual.treadAuthRemoved'],
    shippingMethodDetailsRow: content['checkout']['shipping.methodDetails.actual.row'],
    shippingMethodDetailsAccessories:
      content['checkout']['shipping.methodDetails.actual.accessories'],
    deliverySetup: content['checkout']['reviews.form.ratingDeliveryAndSetup'],
    emailInValid: content['checkout']['pgBikeReviews.form.errors.email.valid'],
    giftCardInvalidPin: content['checkout']['checkout.giftCard.invalidPin'],
    giftCardAmountApplied: content['checkout']['checkout.giftCard.amountApplied'],
    giftCardAmountRemaining: content['checkout']['checkout.giftCard.amtRemaining'],
    giftCardInvalidCardNo: content['checkout']['checkout.giftCard.invalidCardNo'],
    giftCardSupportContact: content['checkout']['checkout.giftCard.supportContact'],
    giftCardsVerifying: content['checkout']['checkout.giftCard.verifying'],
    giftCardsNumberLabel: content['checkout']['checkout.giftCard.number'],
    giftCardsPinNumberLabel: content['checkout']['checkout.giftCard.pinNum'],
    giftCards: content['checkout']['checkout.giftCard.cards'],
    giftCardsRemainingTotal: content['checkout']['checkout.giftCard.remainingTotal'],
    addGiftCards: content['checkout']['checkout.giftCard.add'],
    giftCardRemovingError: content['checkout']['checkout.gifCard.removingCardErr'],
    giftCardDuePayment: content['checkout']['checkout.giftCard.duePayment'],
    confirmationAckWithoutLink:
      content['checkout']['checkout.review.confirmationAckWithoutLink'],
    payOverTimeLabel: content['checkout']['checkout.form.paymentOptions.financing'],
    paymentButtonLabel: content['checkout']['financing.affirm.button.cta'],
    affirmTitle: content['checkout']['checkout.payment.affirmTitle'],
    affirmBlock: content['checkout']['checkout.payment.financing.affirm'],
    affirmPolicy: content['checkout']['checkout.payment.affirm.policy'],
    citizensEligibilityLabel:
      content['checkout']['checkout.payment.citizens.eligibility'],
    citizensBlock: content['checkout']['financing.citizens.block'],
    giftCardLabel: content['checkout']['checkout.giftCard.card'],
    giftCardEndingIn: content['checkout']['checkout.giftCard.cardEndingIn'],
    giftCardEnding: content['checkout']['checkout.giftCard.cardEnding'],
    andText: content['checkout']['checkout.giftCard.andText'],
    zipTitle: content['checkout']['checkout.payment.zipDesc'],
    zipTitle2: content['checkout']['checkout.payment.zipDesc2'],
    zipBlock: content['checkout']['financing.zip.button.cta'],
    zipInstruction: content['checkout']['checkout.payment.zipInstruction'],
    zipDisclaimer: content['checkout']['checkout.payment.zipDisclaimer'],
    klarnaDisclaimer: content['checkout']['checkout.payment.klarnaDisclaimer'],
    klarnaConfirmation: content['checkout']['checkout.payment.klarnaConfirmation'],
    klarnaPaymentButtonLabel: content['checkout']['financing.klarna.button.cta'],
    ssoButtonsApple: content['checkout']['checkout.SSO.buttons.apple'],
    ssoButtonsGoogle: content['checkout']['checkout.SSO.buttons.google'],
    ssoButtonsEmail: content['checkout']['checkout.SSO.buttons.email'],
    ssoCheckoutLegend: content['checkout']['checkout.SSO.legend'],
    ssoCheckoutOrText: content['checkout']['checkout.SSO.orText'],
    accountExistsModalHeading: content['checkout']['socialLinking.accountExists'],
    socialLinkingErrors10502: content['checkout']['socialLinking.errors.errorCode.10502'],
    socialLinkingErrors10503: content['checkout']['socialLinking.errors.errorCode.10503'],
    socialLinkingErrors20001: content['checkout']['socialLinking.errors.errorCode.20001'],
    socialLinkingErrors20101: content['checkout']['socialLinking.errors.errorCode.20101'],
    socialLinkingErrors20102: content['checkout']['socialLinking.errors.errorCode.20102'],
    socialLinkingErrors20103: content['checkout']['socialLinking.errors.errorCode.20103'],
    socialLinkingErrors20104: content['checkout']['socialLinking.errors.errorCode.20104'],
    socialLinkingErrors20105: content['checkout']['socialLinking.errors.errorCode.20105'],
    socialLinkingErrors20106: content['checkout']['socialLinking.errors.errorCode.20106'],
    socialLinkingErrors20107: content['checkout']['socialLinking.errors.errorCode.20107'],
    socialLinkingErrors20109: content['checkout']['socialLinking.errors.errorCode.20109'],
    socialLinkingErrors20999: content['checkout']['socialLinking.errors.errorCode.20999'],
    socialLinkingErrors20110: content['checkout']['socialLinking.errors.errorCode.20110'],
    socialLinkingErrors20111: content['checkout']['socialLinking.errors.errorCode.20111'],
    socialLinkingErrors20601: content['checkout']['socialLinking.errors.errorCode.20601'],
    socialLinkingErrors20602: content['checkout']['socialLinking.errors.errorCode.20602'],
    socialLinkingErrors20701: content['checkout']['socialLinking.errors.errorCode.20701'],
    socialLinkingErrors20702: content['checkout']['socialLinking.errors.errorCode.20702'],
    paymentInfo: content['checkout']['checkout.giftCard.paymentInfo'],
    insufficientFunds: content['checkout']['checkout.giftCard.insufficientFunds'],
    invalidPinOrCurrency: content['checkout']['checkout.giftCard.invalidPinOrCurrency'],
    genericError: content['checkout']['checkout.giftCard.genericError'],
    cardLimitError: content['checkout']['checkout.giftCard.cardLimitError'],
    defaultError: content['checkout']['checkout.digitalCheckout.errors.default'],
    removingCardErr: content['checkout']['checkout.gifCard.removingCardErr'],
    invalidPromoCode: content['checkout']['checkout.form.errors.invalidPromoCode'],
    creditCardExpires: content['checkout']['checkout.review.creditCardExpires'],
    postalCodeDoesNotExist:
      content['checkout']['checkout.errors.shipping.postalCodeDoesNotExist'],
    addedCardText: content['checkout']['checkout.giftCard.addedCardText'],
    apple: content['checkout']['checkout.SSO.buttons.apple'],
    google: content['checkout']['checkout.SSO.buttons.google'],
    email: content['checkout']['checkout.SSO.buttons.email'],
    legend: content['checkout']['checkout.SSO.legend'],
    orText: content['checkout']['checkout.SSO.orText'],
    warrantyInfo: content['checkout']['checkout.warranty.info'],
    financingWarranty: content['checkout']['checkout.warranty.financing'],
    legalWarranty: content['checkout']['checkout.warranty.legal'],
    warrantyHeading: content['checkout']['checkout.warranty.heading'],
    warrantyLearnMore: content['checkout']['checkout.warranty.learnMore'],
    warrantyOption: content['checkout']['warranty.option'],
    checkoutPaymentError: content['checkout']['checkout.paymentMethod.errorText'],
    warrantyBikeMarkdown: content['checkout']['warranty.modal.bike.markdown'],
    warrantyTreadMarkdown: content['checkout']['warranty.modal.tread.markdown'],
    warrantyRowMarkdown: content['checkout']['warranty.modal.row.markdown'],
    warrantyGuideMarkdown: content['checkout']['warranty.modal.guide.markdown'],
    warrantyRefurbBikeMarkdown: content['checkout']['warranty.modal.refurbbike.markdown'],
    warrantyRefurbBikePlusMarkdown:
      content['checkout']['warranty.modal.refurbbikeplus.markdown'],
    warrantySupportText: content['checkout']['warranty.modal.bike.support'],
    warrantyModalContentLabel: content['checkout']['warranty.modal.bike.contentLabel'],
    warrantyModalCta: content['checkout']['warranty.modal.bike.cta'],
    warrantyBikeImage: content['checkout']['warranty.modal.bike.image'],
    signInModalSubtitle: content['checkout']['checkout.user.signInModal.subtitle'],
    signInModalTitle: content['checkout']['checkout.user.signInModal.title'],
    signInCta: content['checkout']['checkout.user.signInModal.signInLabel'],
    giftCardFreeTrialDisclaimer:
      content['checkout']['checkout.giftCard.freeTrialDisclaimer'],
    giftCheckboxToolTip: content['checkout']['checkout.giftCheckbox.toolTip'],
    giftCheckboxDescription: content['checkout']['checkout.giftCheckbox.description'],
    enterCreditCardFormLabel:
      content['checkout']['checkout.giftCard.creditCardFormLabel'],
    googlePayLabel: content['checkout']['checkout.form.paymentOptions.google'],
    applePayLabel: content['checkout']['checkout.form.paymentOptions.apple'],
    paymentRequestTax: content['checkout']['checkout.paymentRequest.tax'],
    paymentRequestDiscount: content['checkout']['checkout.paymentRequest.promo'],
    paymentRequestShipping: content['checkout']['checkout.paymentRequest.shipping'],
    paymentRequestGiftCard: content['checkout']['checkout.paymentRequest.giftCards'],
    paymentRequestTotal: content['checkout']['checkout.paymentRequest.total'],
    paymentRequestTotalTaxIncluded:
      content['checkout']['checkout.paymentRequest.totalTaxIncluded'],
    klarnaLegalCheckbox: content['checkout']['checkout.financing.acceptedCheckbox'],
    klarnaLeavingSiteMsg: content['checkout']['checkout.financing.klarna.leavingSite'],
    klarnaApplyButton: content['checkout']['financing.klarna.button.apply'],
    klarnaFinancingLegalCopy: content['checkout']['checkout.financing.legalCopy'],
    userSignInText: content['checkout']['checkout.user.signIn.text'],
    userSignInCta: content['checkout']['checkout.user.signIn.cta'],
    privacyPolicyOpt: content['checkout']['checkout.email.privacyPolicyOpt'],
    completeFinancingLoading: content['checkout']['checkout.completeFinancing.loading'],
    allAccessMembershipHeader: content['checkout']['checkout.aam.header'],
    allAccessMembershipInformation: content['checkout']['checkout.aam.info'],
    allAccessMembershipOption: content['checkout']['checkout.aam.option'],
    allAccessMembershipSupportLink: content['checkout']['checkout.aam.supportLink'],
    allAccessMembershipLearnMore: content['checkout']['checkout.aam.learnMore'],
    shippingMessage: content['checkout']['checkout.form.shippingMessage'],
    checkoutLegalDisclaimer: content['checkout']['checkoutLegalDisclaimer.Row'],
    checkoutChat: content['checkout']['checkout.form.chatCta'],
    dateOfBirth: content['checkout']['creditCheck.form.labels.dob'],
    invalidDateFormat: content['checkout']['checkout.date.invalidFormat'],
    minimumDateRequired: content['checkout']['checkout.date.minimumDate'],
    dateOfBirthRequired: content['checkout']['creditCheck.form.labels.dobRequired'],
    residentialAddress:
      content['checkout']['creditCheck.form.eyebrow.residentialAddress'],
    residentialPreviousAddress:
      content['checkout']['creditCheck.form.eyebrow.residentialPreviousAddress'],
    residentialAddressSubtext:
      content['checkout']['creditCheck.form.eyebrow.residentialAddress.subText'],
    addPreviousAddress: content['checkout']['creditCheck.form.addPreviousAddress'],
    residentTo: content['checkout']['creditCheck.form.residentTo'],
    residentToRequired: content['checkout']['creditCheck.form.residentToRequired'],
    residentFrom: content['checkout']['creditCheck.form.residentFrom'],
    residentFromRequired: content['checkout']['creditCheck.form.residentFromRequired'],
    removeAddress: content['checkout']['creditCheck.form.removeAddress'],
    personalInformation:
      content['checkout']['creditCheck.form.eyebrow.personalInformation'],
    creditCheckDetails: content['checkout']['creditCheck.form.body.creditCheck.details'],
    creditCheckHeadline: content['checkout']['creditCheck.form.headline.creditCheck'],
    residentLength: content['checkout']['creditCheck.form.eyebrow.residentLength'],
    houseNameRequired: content['checkout']['checkout.address.houseName.required'],
    runCreditCheck: content['checkout']['creditCheck.form.button.runCreditCheck'],
    runCreditCheckSupport:
      content['checkout']['creditCheck.form.button.runCreditCheckSupport'],
    failedCheckHeadlineText:
      content['checkout']['creditCheck.failed.page.failedCheckHeadlineText'],
    failedCheckBodyText:
      content['checkout']['creditCheck.failed.page.failedCheckBodyText'],
    failedCheckButtonText:
      content['checkout']['creditCheck.failed.page.failedCheckButtonText'],
    successfulCreditCheckoutToast: content['checkout']['creditCheck.review.toast'],
    creditCheckReviewPaymentDisclaimer:
      content['checkout']['creditCheck.review.paymentDisclaimer'],
    returnPolicyFooter: content['checkout']['checkout.footer.returnPolicy'],
    returnPolicyFooterLink: content['checkout']['checkout.footer.returnPolicyLink'],
    warrantyFooter: content['checkout']['checkout.footer.warranty'],
    warrantyFooterLink: content['checkout']['checkout.footer.warrantyLink'],
    shippingFooter: content['checkout']['checkout.footer.shipping'],
    shippingFooterLink: content['checkout']['checkout.footer.shippingLink'],
    privacyPolicyFooter: content['checkout']['checkout.footer.privacyPolicy'],
    privacyPolicyFooterLink: content['checkout']['checkout.footer.privacyPolicyLink'],
    liveChatDesktop: content['checkout']['checkout.footer.liveChatDesktop'],
    liveChatMobile: content['checkout']['checkout.footer.liveChatMobile'],
    financingFooter: content['checkout']['checkout.footer.financing'],
    productPartnershipFooterLink:
      content['checkout']['checkout.footer.productPartnershipFooterLink'],
    productPartnershipFooter:
      content['checkout']['checkout.footer.productPartnershipFooter'],
    financialServiceLink: content['checkout']['checkout.footer.financialServiceLink'],
    financialServiceFooter: content['checkout']['checkout.footer.financialServiceFooter'],
    copyrightFooter: content['checkout']['checkout.footer.copyright'],
    userNotFound: content['checkout']['creditCheck.error.userNotFound'],
    systemError: content['checkout']['creditCheck.error.system'],
    paymentDeclined: content['checkout']['checkout.paymentMethod.paymentDeclined'],
  } as const;
};

export default mapToView;
export type CheckoutContent = ReturnType<typeof mapToView>;
