import { push } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  take,
  getContext,
  put,
  race,
  select,
  takeEvery,
} from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { setJwtToCookies } from '@peloton/auth/authToken';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { LOCAL_STORAGE_CUSTOM_EVENT_TYPE } from '@peloton/hooks/shared/useLocalStorage';
import { toLocaleFromHostname } from '@peloton/internationalize';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/models/path';
import { redirect as externalRedirect } from '@peloton/navigation';
import { selectCart } from '@ecomm/cart';
import { getCartRemainingTotal } from '@ecomm/cart/redux/selectors/base';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { finalizePayments, Actions as PaymentActions } from '@ecomm/payment/redux';
import { submitOrder } from '../api';
import { ErrorCode } from '../models';
import type { OrderReceipt } from '../models/OrderReceipt';
import { getMarketingPreference, getAcceptedLeaseAgreement } from '../redux';
import { getGiftCardOrderPayments } from '../redux/selectors';
import type { OrderSubmitAction } from '../redux/submitOrder';
import { Actions as OrderActions, submitOrderFailure } from '../redux/submitOrder';

export const submitOrderSaga = function* (
  client: Client,
  action: OrderSubmitAction,
): SagaIterator {
  const { captchaToken, captchaVersion } = action.payload;

  try {
    const { cart } = yield select(selectCart);
    const allowMarketing = yield select(getMarketingPreference);
    const acceptedLeaseAgreement = yield select(getAcceptedLeaseAgreement);

    const isOauthCookiesEnabled = yield select(getIsToggleActive('oauth_cookie_enabled'));
    const isGiftCardEnabled = yield select(getIsToggleActive('legacy_cfu_gift_card'));
    const giftCardPayments = yield select(getGiftCardOrderPayments);
    const remainingTotal = yield select(getCartRemainingTotal);

    const hasGiftCardPayments =
      isGiftCardEnabled && giftCardPayments && giftCardPayments.length > 0;
    const amount = hasGiftCardPayments ? remainingTotal : cart.total;

    let token = action.payload.token;

    if (action.payload.token.kind === 'stripePaymentIntent') {
      // handles confirming the payment intent
      yield put(
        finalizePayments(
          'stripePaymentIntent',
          { amount, currency: cart.currency },
          captchaToken,
          captchaVersion,
        ),
      );

      const { success, error } = yield race({
        success: take(PaymentActions.FinalizePaymentsSuccess),
        error: take(PaymentActions.FinalizePaymentsFailure),
      });

      if (success) {
        token = success.token;
      } else if (error) {
        throw error.payload.error;
      }
    }

    let payments = [
      {
        token,
        amount,
        method: action.payload.method,
      },
    ];

    if (hasGiftCardPayments) {
      if (action.payload.token.kind === 'gift_card') {
        payments = giftCardPayments;
      } else {
        payments[0].amount = remainingTotal;
        payments = payments.concat(giftCardPayments);
      }
    }

    const receipt = yield call(submitOrder, client, {
      allowMarketing,
      hasAcceptedPolicy: true,
      hasAcceptedLeaseAgreement: acceptedLeaseAgreement,
      payments,
      onlyIncludes: ['id', 'external_id'],
    });

    if (receipt.hasOwnProperty('accessToken')) {
      yield call(
        [localStorage, 'setItem'],
        CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
        JSON.stringify(receipt.accessToken),
      );

      yield call(
        dispatchEvent,
        new StorageEvent(LOCAL_STORAGE_CUSTOM_EVENT_TYPE, {
          key: CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
        }),
      );

      if (isOauthCookiesEnabled) {
        yield call(setJwtToCookies, receipt.accessToken);
      }
    }

    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess);
    }

    yield call(orderSubmitSuccessSaga, receipt);
  } catch (error) {
    let reportedError;

    const giftCardPayments = yield select(getGiftCardOrderPayments);

    const { responseBody = {}, ...originalError } = error.originalError || {};

    const errorMessage =
      error.message === ErrorCode.Default ? responseBody.message : error.message;

    const codes = {
      errorCode: responseBody.errorCode,
      status: responseBody.status,
    };

    if (giftCardPayments && giftCardPayments.length > 0) {
      reportedError = new DomainError('Gift Card | Checkout failed', {
        ...responseBody,
        ...originalError,
      });
    } else {
      reportedError = new DomainError(
        `complete checkout [${JSON.stringify(codes)}]: ${errorMessage}`,
        {
          ...responseBody,
          ...originalError,
        },
      );
    }

    if (action.payload.onError) {
      yield call(action.payload.onError, error);
    }

    yield all([
      put(submitOrderFailure(error)),
      put(reportError({ error: reportedError })),
    ]);
  }
};

export const orderSubmitSuccessSaga = function* (receipt: OrderReceipt) {
  // delivery url looks relative, but the page is proxied from the account app
  // so we need to do an actual redirect on window, rather than using redux
  const i18nUrlForDelivery = addCurrentLocaleToUrl(
    `/delivery/${receipt.data.externalId || receipt.data.id}`,
    toLocaleFromHostname(),
  );
  yield call(externalRedirect, i18nUrlForDelivery);
};

export const submitOrderFailureSaga = function* () {
  const i18nCheckoutUrl = addCurrentLocaleToUrl('/checkout', toLocaleFromHostname());
  yield put(push(i18nCheckoutUrl));
};

const sagas = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(OrderActions.OrderSubmit, submitOrderSaga, client);
  yield takeEvery(OrderActions.OrderSubmitFailure, submitOrderFailureSaga);
};

export default sagas;
