import { DRIFT_DEFAULT_INTERACTION_ID, toLocale } from '@peloton/internationalize';
import { adaExists, openAdaChat } from './ada';
import { driftExists, startDriftInteraction } from './drift';

export const openChat = async () => {
  try {
    const interactionId = DRIFT_DEFAULT_INTERACTION_ID[toLocale()];
    if (driftExists() && interactionId) {
      startDriftInteraction(interactionId);
    } else if (adaExists()) {
      await openAdaChat();
    } else {
      (window as any).$zopim.livechat.window.show();
    }
  } catch (e) {
    if (e instanceof TypeError) {
      console.warn('Chat has not been initialized'); // TODO: improve error reporting
    } else {
      throw e;
    }
  }
};
