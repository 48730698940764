import { USE_APOLLO_V3_ECOPY, HIDE_CONSOLE_LOGS } from '@peloton/app-config';
import type { Locale } from '@peloton/internationalize';
import { CONTENTFUL_TOKEN } from '@ecomm/contentful/accessToken';
import type { PublishedCopyBlob } from '@ecomm/copy/helpers';
import toBaseClientV2 from './toClient';
import toBaseClientV3 from './toClientV3';

type Options = {
  locale: Locale;
  preview: boolean;
  publishedLocaleCopy: PublishedCopyBlob;
  tag: string;
};

// This needs to use a different FF because the test env needs to be able to be separated from next-www
let toBaseClient = toBaseClientV2;
if (USE_APOLLO_V3_ECOPY || process.env.FF_ENABLE_APOLLO_V3_ECOPY === 'true') {
  if (process.env.NODE_ENV !== 'test' && !HIDE_CONSOLE_LOGS) {
    console.log('USE_APOLLO_V3 toCopyClient', USE_APOLLO_V3_ECOPY);
  }
  // @ts-expect-error
  toBaseClient = toBaseClientV3;
}

const toClient = (opts: Options) =>
  toBaseClient({
    ...opts,
    token: CONTENTFUL_TOKEN,
    fetch,
  });

export default toClient;
