import React from 'react';
import { Redirect } from 'react-router-dom';
import type { ToggleId } from '@ecomm/feature-toggle';
import { Route } from '@ecomm/router';
import type { Redirects, RedirectConfig } from './types';

const toRedirects = <T extends ToggleId>(redirectConfigs: Redirects<T>) =>
  redirectConfigs.map(config => toRedirect(config));

export default toRedirects;

const toRedirect = <T extends ToggleId>({ from, to, toggle }: RedirectConfig<T>) => (
  <Route
    key={from}
    path={from}
    exact
    page
    toggleId={toggle}
    component={() => <Redirect to={to} />}
  />
);
