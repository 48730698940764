import { useMemo } from 'react';
import toFormattedText from '@peloton/copy/toFormattedText';
import {
  localeToMembershipPriceForGuidePricing,
  useLocale,
} from '@peloton/internationalize';
import useDiscountsByBundleType from '@content/client/hooks/useDiscountsByBundleType';
import { useBikePlusPromo } from '@ecomm/feature-toggle/BikePlusPromoDiscounts';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useStrikethroughPriceOffsetMap } from '@ecomm/feature-toggle/MockupStrikethroughPrice';
import useFinancing from '@ecomm/financing/hooks/useFinancingNext';
import { toDollars } from '@ecomm/models/Money';
import { useRentalPrices } from '@ecomm/rentals/models';
import { BundleType } from '@ecomm/shop/models/BundleType';
import useDigitalPrice from '@ecomm/subscription-plans/useDigitalPrice';
import useTierMobilityPrices from '@ecomm/subscription-plans/useTierMobilityPrices';

export type Pricing = {
  bikeTotal: number;
  bikeTotalBeforeShipping: number;
  bikeShippingEstimate: number;
  bikeMonthlyPayment: number;
  bikeMaxTerm: number;
  bikePlusTotal: number;
  bikePlusMonthlyPayment: number;
  bikePlusMaxTerm: number;
  bikePlusTotalBeforeShipping: number;
  bikePlusShippingEstimate: number;
  treadTotal: number;
  treadTotalBeforeShipping: number;
  treadShippingEstimate: number;
  treadMonthlyPayment: number;
  treadMaxTerm: number;
  treadPlusTotal: number;
  treadPlusMonthlyPayment: number;
  treadPlusMaxTerm: number;
  digitalPrice: number;
  membershipPrice: number;
  guideTotal: number;
  guideTotalBeforeShipping: number;
  guideShippingEstimate: number;
  guideMonthlyPayment: number;
  guideMaxTerm: number;
  guideMembershipPrice: number;
  rowTotalBeforeShipping: number;
  guideStrikethrough: number;
  refurbishedBikeTotal: number;
  refurbishedBikeTotalBeforeShipping: number;
  refurbishedBikeShippingEstimate: number;
  refurbishedBikePlusTotal: number;
  refurbishedBikePlusTotalBeforeShipping: number;
  refurbishedBikePlusShippingEstimate: number;
  bikeRentalSetupPrice: number;
  bikeRentalMonthlyPrice: number;
  bikePlusRentalSetupPrice: number;
  bikePlusRentalMonthlyPrice: number;
};

const formatPrice = (priceCents: number) => {
  return toFormattedText('{price, number, currency}', {
    price: toDollars(priceCents),
  });
};

const formatPricePromoStrikethrough = (
  basePrice: number,
  totalPriceWithDiscount: number,
) => {
  if (basePrice === totalPriceWithDiscount) {
    return formatPrice(basePrice);
  } else {
    return `_${formatPrice(totalPriceWithDiscount)}_ ~${formatPrice(basePrice)}~`;
  }
};

const formatOptionalStrikethrough = (
  basePrice: number,
  totalPriceWithDiscount: number,
) => {
  if (basePrice === totalPriceWithDiscount) {
    return '';
  } else {
    return ` ~${formatPrice(basePrice)}~`;
  }
};

export const useFinancingValues = (): Pricing => {
  const locale = useLocale();
  const digitalPrice = useDigitalPrice();
  const isToggleActive = useIsToggleActive();
  const isIncreasedMembershipPriceEnabled = isToggleActive(
    'increasedAllAccessMembershipPrice',
  );
  const membershipPrice = localeToMembershipPriceForGuidePricing(
    locale,
    isIncreasedMembershipPriceEnabled,
  );

  const { discount, monthlyPromo, isPromo } = useBikePlusPromo(
    BundleType.BikePlus,
    'boxingDayBikePlusPromo',
  );

  const bike = useFinancing(BundleType.Bike);
  const bikePlus = useFinancing(BundleType.BikePlus);
  const tread = useFinancing(BundleType.Tread);
  const treadPlus = useFinancing(BundleType.TreadPlus);
  const guide = useFinancing(BundleType.RainforestCafe);
  const row = useFinancing(BundleType.Row);
  const refurbBike = useFinancing(BundleType.RefurbishedBike);
  const refurbBikePlus = useFinancing(BundleType.RefurbishedBikePlus);

  const BIKE_RENTAL_PRICE = useRentalPrices('BIKE', locale);
  const BIKE_PLUS_RENTAL_PRICE = useRentalPrices('BIKE_PLUS', locale);

  const {
    mockupStrikethroughPriceOffset: guidePriceOffset,
  } = useStrikethroughPriceOffsetMap('', BundleType.RainforestCafe);

  const digitalPriceList = useTierMobilityPrices();

  const discountsByBundleType = useDiscountsByBundleType();

  const values = useMemo(() => {
    const {
      total: bikeTotal,
      totalBeforeShipping: bikeTotalBeforeShipping,
      shippingEstimate: bikeShippingEstimate,
      monthly: bikeMonthlyPayment,
      term: bikeMaxTerm,
    } = bike;
    const bikeDiscountTotal = bikeTotal - discountsByBundleType[BundleType.Bike];

    const {
      total: bikePlusTotal,
      totalBeforeShipping: bikePlusTotalBeforeShipping,
      shippingEstimate: bikePlusShippingEstimate,
      monthly: bikePlusMonthlyPayment,
      term: bikePlusMaxTerm,
    } = bikePlus;
    const bikePlusDiscountTotal =
      bikePlusTotal - discountsByBundleType[BundleType.BikePlus];

    const {
      total: treadTotal,
      totalBeforeShipping: treadTotalBeforeShipping,
      shippingEstimate: treadShippingEstimate,
      monthly: treadMonthlyPayment,
      term: treadMaxTerm,
    } = tread;
    const treadDiscountTotal = treadTotal - discountsByBundleType[BundleType.Tread];

    const {
      total: treadPlusTotal,
      monthly: treadPlusMonthlyPayment,
      term: treadPlusMaxTerm,
    } = treadPlus;
    const treadPlusDiscountTotal =
      treadPlusTotal - discountsByBundleType[BundleType.TreadPlus];

    const {
      total: guidePrice,
      totalBeforeShipping: guideTotalBeforeShipping,
      shippingEstimate: guideShippingEstimate,
      monthly: guideMonthlyPrice,
      term: guideMaxTerm,
      membershipPrice: guideMembershipPrice,
    } = guide;
    const guideDiscountTotal =
      guidePrice - discountsByBundleType[BundleType.RainforestCafe];

    const {
      total: rowTotal,
      totalBeforeShipping: rowTotalBeforeShipping,
      monthly: rowMonthlyPayment,
    } = row;
    const rowDiscountTotal = rowTotal - discountsByBundleType[BundleType.Row];

    const {
      total: refurbBikePrice,
      totalBeforeShipping: refurbBikeTotalBeforeShipping,
      shippingEstimate: refurbBikeShippingEstimate,
      monthly: refurbBikeMonthlyPayment,
    } = refurbBike;
    const refurbBikeDiscountTotal =
      refurbBikePrice - discountsByBundleType[BundleType.RefurbishedBike];

    const {
      total: refurbBikePlusPrice,
      totalBeforeShipping: refurbBikePlusTotalBeforeShipping,
      shippingEstimate: refurbBikePlusShippingEstimate,
    } = refurbBikePlus;
    const refurbBikePlusDiscountTotal =
      refurbBikePlusPrice - discountsByBundleType[BundleType.RefurbishedBikePlus];

    const {
      setup: bikeRentalSetupPrice,
      monthly: bikeRentalMonthlyPrice,
    } = BIKE_RENTAL_PRICE;

    const {
      setup: bikePlusRentalSetupPrice,
      monthly: bikePlusRentalMonthlyPrice,
    } = BIKE_PLUS_RENTAL_PRICE;

    return {
      ...digitalPriceList,

      // v1 Bike
      bikeTotal: toDollars(bikeTotal),
      bikeDiscountTotal: toDollars(bikeDiscountTotal),
      bikeTotalWithStrikethrough: formatPricePromoStrikethrough(
        bikeTotal,
        bikeDiscountTotal,
      ),
      bikeOptionalStrikethrough: formatOptionalStrikethrough(
        bikeTotal,
        bikeDiscountTotal,
      ),
      bikeTotalBeforeShipping: toDollars(bikeTotalBeforeShipping),
      bikeShippingEstimate: toDollars(bikeShippingEstimate),
      bikeMonthlyPayment: toDollars(bikeMonthlyPayment),
      bikeMaxTerm,

      // Bike+
      bikePlusTotal: toDollars(bikePlusTotal - discount),
      bikePlusDiscountTotal: toDollars(bikePlusDiscountTotal),
      bikePlusTotalWithStrikethrough: formatPricePromoStrikethrough(
        bikePlusTotal,
        bikePlusDiscountTotal,
      ),
      bikePlusOptionalStrikethrough: formatOptionalStrikethrough(
        bikePlusTotal,
        bikePlusDiscountTotal,
      ),
      bikePlusTotalBeforeShipping: toDollars(bikePlusTotalBeforeShipping),
      bikePlusShippingEstimate: toDollars(bikePlusShippingEstimate),
      bikePlusMonthlyPayment: toDollars(isPromo ? monthlyPromo : bikePlusMonthlyPayment),
      bikePlusMaxTerm,

      // Tread
      treadTotal: toDollars(treadTotal),
      treadDiscountTotal: toDollars(treadDiscountTotal),
      treadTotalWithStrikethrough: formatPricePromoStrikethrough(
        treadTotal,
        treadDiscountTotal,
      ),
      treadOptionalStrikethrough: formatOptionalStrikethrough(
        treadTotal,
        treadDiscountTotal,
      ),
      treadTotalBeforeShipping: toDollars(treadTotalBeforeShipping),
      treadShippingEstimate: toDollars(treadShippingEstimate),
      treadMonthlyPayment: toDollars(treadMonthlyPayment),
      treadMaxTerm,

      // Tread+
      treadPlusTotal: toDollars(treadPlusTotal),
      treadPlusDiscountTotal: toDollars(treadPlusDiscountTotal),
      treadPlusTotalWithStrikethrough: formatPricePromoStrikethrough(
        treadPlusTotal,
        treadPlusDiscountTotal,
      ),
      treadPlusOptionalStrikethrough: formatOptionalStrikethrough(
        treadPlusTotal,
        treadPlusDiscountTotal,
      ),
      treadPlusMonthlyPayment: toDollars(treadPlusMonthlyPayment),
      treadPlusMaxTerm,

      // Guide
      guideTotal: toDollars(guidePrice),
      guideDiscountTotal: toDollars(guideDiscountTotal),
      guideTotalWithStrikethrough: formatPricePromoStrikethrough(
        guidePrice,
        guideDiscountTotal,
      ),
      guideOptionalStrikethrough: formatOptionalStrikethrough(
        guidePrice,
        guideDiscountTotal,
      ),
      guideTotalBeforeShipping: toDollars(guideTotalBeforeShipping),
      guideShippingEstimate: toDollars(guideShippingEstimate),
      guideMonthlyPayment: toDollars(guideMonthlyPrice),
      guideMaxTerm,
      guideStrikethrough: toDollars(guidePrice + guidePriceOffset),

      // Row
      rowTotal: toDollars(rowTotal),
      rowDiscountTotal: toDollars(rowDiscountTotal),
      rowTotalWithStrikethrough: formatPricePromoStrikethrough(
        rowTotal,
        rowDiscountTotal,
      ),
      rowOptionalStrikethrough: formatOptionalStrikethrough(rowTotal, rowDiscountTotal),
      rowMonthlyPayment: toDollars(rowMonthlyPayment),
      rowTotalBeforeShipping: toDollars(rowTotalBeforeShipping),

      // v1 Bike Refurb
      refurbishedBikeTotal: toDollars(refurbBikePrice),
      refurbishedBikeDiscountTotal: toDollars(refurbBikeDiscountTotal),
      refurbishedBikeTotalWithStrikethrough: formatPricePromoStrikethrough(
        refurbBikePrice,
        refurbBikeDiscountTotal,
      ),
      refurbishedBikeOptionalStrikethrough: formatOptionalStrikethrough(
        refurbBikePrice,
        refurbBikeDiscountTotal,
      ),
      refurbBikeMonthlyPayment: toDollars(refurbBikeMonthlyPayment),
      refurbishedBikeTotalBeforeShipping: toDollars(refurbBikeTotalBeforeShipping),
      refurbishedBikeShippingEstimate: toDollars(refurbBikeShippingEstimate),

      // Bike+ Refurb
      refurbishedBikePlusTotal: toDollars(refurbBikePlusPrice),
      refurbishedBikePlusDiscountTotal: toDollars(refurbBikePlusDiscountTotal),
      refurbishedBikePlusTotalWithStrikethrough: formatPricePromoStrikethrough(
        refurbBikePlusPrice,
        refurbBikePlusDiscountTotal,
      ),
      refurbishedBikePlusOptionalStrikethrough: formatOptionalStrikethrough(
        refurbBikePlusPrice,
        refurbBikePlusDiscountTotal,
      ),
      refurbishedBikePlusTotalBeforeShipping: toDollars(
        refurbBikePlusTotalBeforeShipping,
      ),
      refurbishedBikePlusShippingEstimate: toDollars(refurbBikePlusShippingEstimate),

      // Memberships
      digitalPrice: toDollars(digitalPrice),
      membershipPrice: toDollars(membershipPrice),
      guideMembershipPrice: toDollars(guideMembershipPrice),

      // Rentals
      // Rental prices are stored in USD
      bikeRentalSetupPrice,
      bikeRentalMonthlyPrice,
      bikePlusRentalSetupPrice,
      bikePlusRentalMonthlyPrice,
    };
  }, [
    bike,
    bikePlus,
    tread,
    treadPlus,
    guide,
    row,
    refurbBike,
    refurbBikePlus,
    BIKE_RENTAL_PRICE,
    BIKE_PLUS_RENTAL_PRICE,
    digitalPriceList,
    discountsByBundleType,
    discount,
    isPromo,
    monthlyPromo,
    digitalPrice,
    membershipPrice,
    guidePriceOffset,
  ]);

  return values;
};

export default useFinancingValues;
