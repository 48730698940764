declare global {
  interface Window {
    drift?: {
      api: {
        startInteraction: ({ interactionId }: { interactionId: number }) => void;
      };
    };
  }
}

export const driftExists = () => Boolean(window.drift);

export const startDriftInteraction = (interactionId: number) => {
  if (!window.drift) {
    return;
  }
  return window.drift.api.startInteraction({ interactionId });
};
