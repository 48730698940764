import { toLanguageFromLocale } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize/models/locale';

declare global {
  interface Window {
    adaEmbed?: {
      toggle: () => Promise<void>;
      getInfo: () => Promise<{
        isChatOpen: boolean;
      }>;
    };
    adaSettings?: Record<string, any>;
  }
}

export const ADA_SETTINGS_SCRIPT_ID = '__ada-settings';

const toAdaSettings = (locale: Locale) => {
  return {
    language: toLanguageFromLocale(locale),
  };
};

export const toAdaSettingsString = (locale: Locale) => {
  return `window.adaSettings = ${JSON.stringify(toAdaSettings(locale))}`;
};

export const toAdaScriptProps = (botHandle: string) => {
  return {
    id: '__ada',
    'data-handle': botHandle,
    src: 'https://static.ada.support/embed2.js',
  };
};

export const injectAdaScript = (locale: Locale, botHandle: string) => {
  window.adaSettings = toAdaSettings(locale);

  const chatScript = document.createElement('script');
  const scriptProps = toAdaScriptProps(botHandle);
  for (const [key, value] of Object.entries(scriptProps)) {
    chatScript.setAttribute(key, value);
  }

  document.body.appendChild(chatScript);
};

export const adaExists = () => Boolean(window.adaEmbed);

export const openAdaChat = async () => {
  if (!window.adaEmbed) {
    return;
  }

  const { isChatOpen } = await window.adaEmbed.getInfo();

  if (!isChatOpen) {
    await window.adaEmbed.toggle();
  }
};
