import type { PublishedLocale } from '@peloton/internationalize/models/path';

/**
 * The models for server routing business logic
 */

/**
 * Possible server routing status
 */
export const enum Status {
  Rewrite = '200',
  Redirect_Permanent = '301',
  Redirect_Temporary = '302',
  Not_Found = '404',
}

export type ServerRoute = {
  /** the path or complete URL to route from */
  source: string;

  /** the path or complete URL to route to */
  destination: string;

  /** redirect to the destination, even if the source exists on the CDN.
   * For more info see the
   * [`force` setting in Netlify](https://docs.netlify.com/routing/redirects/#syntax-for-the-netlify-configuration-file))
   */
  dontForce?: boolean;

  /** the countries that the route should be active for using
   * [ISO 3166 country codes](https://dev.maxmind.com/geoip/legacy/codes/iso3166/)
   */
  countries?: string[];

  /**
   * Routing Status
   */
  status?: Status;
};

/**
 * A collection of routes with an optional description
 */
export type ServerRouteGrouping = {
  /**
   * The human-readable description of the collection of routes, used to generate comments in output
   */
  description?: string;

  /**
   * The list of server routes
   */
  routes?: ServerRoute[];
};

/**
 * A collection of redirects that are controlled by toggles
 */
export type ToggledRedirects<ToggleType = any> = Record<
  /**
   * The source for the redirect
   */
  string,
  {
    /**
     * The ID of the toggle that will decide if the enabled or disabled routes should be returned
     */
    toggleId: ToggleType;
    /**
     * A mapping of locales to destinations for when the toggle is enabled or disabled
     */
    destinations: Partial<
      Record<
        PublishedLocale,
        {
          enabled: string;
          disabled?: string;
          /**
           * When true, includes a route without the hostname for staging
           */
          includeStagingRoute?: boolean;
          /**
           * When true, includes the host in the destination, defaults to true
           */
          includeHostInDestination?: boolean;
          /**
           * When true, includes the route when subpath routing is enabled, defaults to true
           */
          includeInSubpathRouting?: boolean;
        }
      >
    >;
    /**
     * Routing status, defaults to temporaru redirect
     */
    status?: Status;
  }
>;
